import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styles from './css/Login.module.css';  // Import the CSS module

const Login = ({ setToken, setIsAdmin, setUser }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Clear "selectedColumns" on Login component mount
  useEffect(() => {
    localStorage.removeItem('selectedColumns');
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { username, password });
      const { access_token, is_admin, username: userUsername, email } = response.data;

      setToken(access_token);
      setIsAdmin(is_admin);
      setUser({ username: userUsername, email });
      localStorage.setItem('token', access_token);  // Store the token in localStorage
      
      if (is_admin) {
        navigate('/admin');
      } else {
        navigate('/client');
      }
    } catch (err) {
      if (err.response) {
        const { message, error_code } = err.response.data;
        if (error_code === 'deactive_user') {
          setError('User is deactivated, login denied');
        } else if (error_code === 'active_session') {
          setError('There is already an active session for this user. NB: Closed sessions takes 5 minutes to expire');
        } else {
          setError(message);
        }
      } else {
        setError('Invalid credentials');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginContainer}>
        <div className={styles.logoContainer}>
          <img src="https://s3.eu-north-1.amazonaws.com/static.s360digital.com/s360-logo-white-1.svg" alt="s360 Logo" />
          <h2 className={styles.appName}>Quantum Feed Engine</h2>
        </div>
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className={styles.loginInput}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className={styles.loginInput}
          />
          <button type="submit" className={styles.loginButton} disabled={isLoading}>
            {isLoading ? 'Loading...' : 'Login'}
          </button>
        </form>
        {error && <p className={styles.loginError}>{error}</p>}
      </div>
    </div>
  );
};

export default Login;
