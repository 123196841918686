// pdpgeneration.js

import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  FaSyncAlt, FaPlus, FaPlay, FaArrowLeft, 
  FaSignOutAlt, FaTimes, FaChevronLeft, 
  FaChevronRight, FaDatabase, FaMagic, FaDownload, FaInfoCircle, FaPencilAlt  
} from 'react-icons/fa';
import Select, { components } from 'react-select';
import ClipLoader from "react-spinners/ClipLoader";
import styles from './css/PdpGeneration.module.css';
import PopupRowDataPdp from '../popups/PopupRowDataPdp';
import fileDownload from 'js-file-download';
import PopupPdpBulkActions from '../popups/PopupPdpBulkActions';




const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;
const API_URL_SCHEDULER = process.env.REACT_APP_API_URL_SCHEDULER;

const PDP_COLUMNS = [
  'meta_title', 'meta_description', 'h1', 'h2', 'intro',
  'description', 'usp', 'faq',
  'custom_field_1', 'custom_field_2', 'custom_field_3',
  'custom_field_4', 'custom_field_5', 'custom_field_6',
  'custom_field_7', 'custom_field_8', 'custom_field_9',
  'custom_field_10'
];


const PdpGeneration = ({ isAdmin, token, selectedClient, setSelectedClient }) => {
  const navigate = useNavigate();

  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadError, setDownloadError] = useState(null);

  const [linkedClients, setLinkedClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [clientError, setClientError] = useState(null);
  const [filters, setFilters] = useState([]); 
  const [filterInputs, setFilterInputs] = useState([
    { column: '', type: 'contains', value: '', logic: 'AND' }
  ]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [sortColumn, setSortColumn] = useState('id');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(null);
  const [refreshError, setRefreshError] = useState(null);
  const [popupSaving, setPopupSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [originalColumnOrder, setOriginalColumnOrder] = useState([]);


  const handleNavigateToPromptBuilder = () => {
    navigate('/prompt-builder-pdp'); // Ensure this route exists in App.js
  };
  
  // Table states
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState(page);
  const [pageError, setPageError] = useState('');


  const [perPage, setPerPage] = useState(100);
  const [totalItems, setTotalItems] = useState(0);
  const [totalItemsAll, setTotalItemsAll] = useState(0);
  const [isPaginating, setIsPaginating] = useState(false);
  

  // Column selection states
  const [selectedColumns, setSelectedColumns] = useState(JSON.parse(localStorage.getItem('pdp_selectedColumns')) || []);
  const [unselectedColumnsRegular, setUnselectedColumnsRegular] = useState([]);
  const [unselectedColumnsPdp, setUnselectedColumnsPdp] = useState([]);
  const [selectedColumnItems, setSelectedColumnItems] = useState([]);
  
  // For shift-click selection
  const [lastClickedColumn, setLastClickedColumn] = useState(null);

  // In your main component:
  const [loadDataStatus, setLoadDataStatus] = useState(null);
  const [loadDataError, setLoadDataError] = useState(null);

  // For drag and drop
  const [draggedColumnIndex, setDraggedColumnIndex] = useState(null);

  // Loading state
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [loadingContext, setLoadingContext] = useState(null); // 'clientChange' | 'filterApply' | null

  const [isBulkActionsPopupOpen, setIsBulkActionsPopupOpen] = useState(false);

  const handleBulkActionsClick = () => {
    setIsBulkActionsPopupOpen(true);
  };


  // Helper function to reorder PDP columns based on PDP_COLUMNS array
  const reorderPdpColumns = (columns) => {
    return PDP_COLUMNS.filter(col => columns.includes(col));
  };

  // Helper function to reorder Regular columns based on originalColumnOrder
  const reorderRegularColumns = (columns) => {
    return columns.slice().sort((a, b) => originalColumnOrder.indexOf(a) - originalColumnOrder.indexOf(b));
  };

  const getDisplayName = (col) => {
    if (col.startsWith('source_')) {
      return col.replace('source_', '');
    }
    return col;
  };
  
  const isSourceColumn = (col) => col === 'id' || col.startsWith('source_');

  const ColumnOption = (props) => {
    const { label, value } = props.data;       // label and value come from { value: col, label: col }
    const isSource = isSourceColumn(value);
  
    return (
      <components.Option {...props}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Left side: the label (with or without "source_" stripped) */}
          <span>{getDisplayName(label)}</span>
          
          {/* Right side: icon */}
          {isSource ? (
            <FaDatabase style={{ marginLeft: '8px' }} title="Source column" />
          ) : (
            <FaMagic style={{ marginLeft: '8px' }} title="Magic column" />
          )}
        </div>
      </components.Option>
    );
  };


  const getCellBackgroundColor = (isRowSelected, isPdpColumn, columnState) => {
    if (isRowSelected) {
      // If the entire row is selected:
      if (isPdpColumn) {
        // PDP column with a known _state
        return columnState ? '#c8dbcf' : '#d7d0b2';
      } else {
        // Regular (non-PDP) columns
        return '#d1d1d1';
      }
    } else {
      // If the row is NOT selected, fall back to your original logic:
      if (isPdpColumn) {
        // If the PDP column has an associated _state
        if (columnState === true) {
          return '#E0EBE4';    // or your existing 'true' background
        } else if (columnState === false) {
          return '#fefbec';    // or your existing 'false' background
        }
      }
      // For regular columns or if no _state is found:
      return 'transparent';
    }
  };


  // Row Popup states
  const [isRowPopupOpen, setIsRowPopupOpen] = useState(false);
  const [rowPopupData, setRowPopupData] = useState(null);

  // Handler to open the popup with a specific row’s data
  const handleOpenRowPopup = (rowData) => {
    setRowPopupData(rowData);
    setIsRowPopupOpen(true);
    setSaveSuccess(false);
  };

  const handleCloseRowPopup = () => {
    setRowPopupData(null);
    setIsRowPopupOpen(false);
  };
  
  const handleRowSelect = (rowId) => {
    setSelectedRows((prevSelected) => {
      if (prevSelected.includes(rowId)) {
        // If currently selected, unselect
        return prevSelected.filter(id => id !== rowId);
      } else {
        // If not selected, add it
        return [...prevSelected, rowId];
      }
    });
  };


  const handleDownloadAllData = async () => {
    if (!selectedClient) return;
  
    setIsDownloading(true);
    setDownloadError(null);
  
    try {
      // Prepare the request body
      const payload = {
        client_name: selectedClient,
        filters,
        selected_columns: displayedColumns,  // or use selectedColumns
        order_by: sortColumn,
        order_direction: sortOrder,
      };
  
      const response = await axios.post(
        `${API_URL_PDP}/export-data`,
        payload,
        {
          headers: { Authorization: `Bearer ${token}` },
          // Important: we expect the server to return a CSV file (blob)
          responseType: 'blob',
        }
      );
  
      // Use 'fileDownload' to save the blob to a CSV file locally
      fileDownload(response.data, 'export.csv'); 
    } catch (error) {
      console.error('Error exporting data:', error);
      setDownloadError(error?.response?.data?.message || 'Error exporting data.');
    } finally {
      setIsDownloading(false);
    }
  };
  
  

  const handleSaveRowChanges = async (updatedFields) => {
    if (!rowPopupData || !selectedClient) return;

    setPopupSaving(true);
    setSaveSuccess(false);

    try {
      const payload = {
        client_name: selectedClient,
        id: rowPopupData.id,
        updated_fields: updatedFields,  // e.g. { meta_title: "<p>hello</p>", description: "...", etc }
      };

      const response = await axios.post(
        `${API_URL_PDP}/update-pdp-row`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        console.log('Row updated successfully:', response.data);
        setSaveSuccess(true);

        // Optionally re-fetch data to refresh the table
        // This ensures the table shows the latest data
        fetchInitialData(selectedClient, page, perPage, filters, sortColumn, sortOrder);
      } else {
        console.error('Update failed:', response.data);
        alert(`Update failed: ${response.data.message || 'Unknown error'}`);
      }

    } catch (error) {
      console.error('Error updating row data:', error);
      alert(`Error: ${error?.response?.data?.message || 'Failed to update data.'}`);
    } finally {
      setPopupSaving(false);
    }
  };


// SingleValue for the chosen option
const ColumnSingleValue = (props) => {
  const { label, value } = props.data;
  const isSource = isSourceColumn(value);

  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* Left side: the label */}
        <span>{getDisplayName(label)}</span>

        {/* Right side: icon */}
        {isSource ? (
          <FaDatabase style={{ marginLeft: '8px' }} title="Source column" />
        ) : (
          <FaMagic style={{ marginLeft: '8px' }} title="Magic column" />
        )}
      </div>
    </components.SingleValue>
  );
};

useEffect(() => {
  setInputPage(page);
}, [page]);


useEffect(() => {
  const fetchLinkedClients = async () => {
    if (!token) return;
    setIsClientsLoading(true);
    try {
      const response = await fetch(`${API_URL_PDP}/client-data`, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      if (response.ok) {
        const data = await response.json();
        setLinkedClients(data.linked_clients.map(client => ({ value: client, label: client })));
        setClientError(null);
        // Removed automatic selection of the first client
      } else {
        setClientError('Failed to load clients');
      }
    } catch (error) {
      console.error('Error fetching clients:', error);
      setClientError('An error occurred');
    } finally {
      setIsClientsLoading(false);
    }
  };
  
  // Fetch linked clients only once when the component mounts
  fetchLinkedClients();
}, [token]); // Ensure 'token' is included if it can change


const handleClientChange = (option) => {
  const clientVal = option ? option.value : '';
  setSelectedClient(clientVal);
  setPage(1);

  // Clear data and column-related states on client change
  setData([]);
  setUnselectedColumnsRegular([]);
  setUnselectedColumnsPdp([]);
  setSelectedColumns([]);
  setOriginalColumnOrder([]);
  setFilterInputs([{ column: '', type: 'contains', value: '', logic: 'AND' }]);
  setFilters([]);

  // Fetch new data for the selected client
  if (clientVal) {
    fetchInitialData(clientVal, 1, perPage, filters, sortColumn, sortOrder, 'clientChange');
  }
};


const handleRefresh = async () => {
  if (!selectedClient) {
    setRefreshStatus('error');
    setRefreshError('No client selected.');
    return;
  }

  setRefreshStatus('loading');
  setRefreshError(null);

  try {
    const response = await axios.get(`${API_URL_SCHEDULER}/refresh-pdp-data`, {
      params: { client_name: selectedClient },
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status === 200) {
      setRefreshStatus('success');
      // <--- Pass 'refresh' as the context here
      fetchInitialData(selectedClient, 1, perPage, filters, sortColumn, sortOrder, 'refresh');
    } else {
      setRefreshStatus('error');
      setRefreshError('Failed to refresh PDP data.');
    }
  } catch (error) {
    setRefreshStatus('error');
    const errMessage = error?.response?.data?.message || 'Error refreshing PDP data.';
    setRefreshError(errMessage);
  }
};

  


  const filterOptions = useMemo(() => {
    if (data.length === 0) return [];

    const allColumns = Object.keys(data[0]);

    // Separate Source and PDP Columns
    const sourceColumns = allColumns.filter(c => c === 'id' || c.startsWith('source_'));
    const pdpColumns = PDP_COLUMNS.filter(c => allColumns.includes(c) && !c.endsWith('_state'));

    // Reorder Columns
    const sortedSourceColumns = reorderRegularColumns(sourceColumns);
    const sortedPdpColumns = reorderPdpColumns(pdpColumns);

    // Combine Source and PDP Columns
    const finalColumns = [...sortedSourceColumns, ...sortedPdpColumns];

    // Map to Select options
    return finalColumns.map(col => ({
      value: col,
      label: col
    }));
  }, [data, originalColumnOrder]);

  const fetchInitialData = async (
    client, page, perPage, filters, orderBy, orderDirection, context
  ) => {
    if (!client) return;
  
    // Set loading status based on context
    if (context === 'pagination') {
      setLoadDataStatus('paginationLoading');
    } else {
      setLoadDataStatus('loading'); // Existing behavior
    }
  
    setLoadDataError(null);
    setIsDataLoading(true);
  
    try {
      const response = await axios.post(
        `${API_URL_PDP}/load-data`,
        {
          client_name: client,
          page,
          per_page: perPage,
          filters,
          order_by: orderBy,
          order_direction: orderDirection
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      const fetchedData = response.data.data || [];
      setData(fetchedData); 
      setTotalItems(response.data.total_items);
      setTotalItemsAll(response.data.total_items_all);
  
      if (fetchedData.length === 0) {
        setLoadDataStatus('noData');
      } else {
        setLoadDataStatus('success');
      }
  
      // Update columns for 'clientChange', 'refresh', or 'initialLoad'
      if (['clientChange', 'refresh', 'initialLoad'].includes(context)) {
        const allColumns = fetchedData.length > 0 ? Object.keys(fetchedData[0]) : [];
        const regularCols = allColumns.filter(c => c === 'id' || c.startsWith('source_'));
        const pdpColsPresent = PDP_COLUMNS.filter(c => allColumns.includes(c));
  
        setUnselectedColumnsRegular(reorderRegularColumns([...regularCols]));
        setUnselectedColumnsPdp(reorderPdpColumns([...pdpColsPresent]));
  
        // Only set originalColumnOrder if it’s empty — so we don’t overwrite it
        if (originalColumnOrder.length === 0) {
          setOriginalColumnOrder(allColumns);
        }
      }
    } catch (error) {
      setLoadDataStatus('error');
      const errMessage = error?.response?.data?.message || 'Error loading data.';
      setLoadDataError(errMessage);
    } finally {
      setIsDataLoading(false);
      if (context === 'pagination') {
        setIsPaginating(false);
      }
      setLoadingContext(null);
    }
  };
  
  
  
  
  
  
  

  useEffect(() => {
    if (!selectedClient) {
      setData([]);
      setTotalItems(0);
      setTotalItemsAll(0);
      return;
    }
  
    // *Only* handle the scenario when the client changes (or filters, etc.), 
    // not page changes:
    const isInitialLoad = originalColumnOrder.length === 0;
    
    fetchInitialData(
      selectedClient,
      page, 
      perPage,
      filters,
      sortColumn,
      sortOrder,
      isInitialLoad ? 'initialLoad' : 'clientChange'
    );
  // remove `page` from dependencies so you don't double-fetch
  }, [selectedClient, perPage, filters, sortColumn, sortOrder]);
  
  
  
  

  // Persist selectedColumns in localStorage
  useEffect(() => {
    localStorage.setItem('pdp_selectedColumns', JSON.stringify(selectedColumns));
  }, [selectedColumns]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const addFilter = () => {
    setFilterInputs([
      ...filterInputs, 
      { column: '', type: 'contains', value: '', logic: 'AND' }
    ]);
  };
  
  const handleApplyFilter = () => {
    setFilters(filterInputs); // Update the filters state
    setPage(1); // Reset to the first page
    setLoadingContext('filterApply'); // Optional: for indicating filter application context
  
    if (selectedClient) {
      fetchInitialData(
        selectedClient, 
        1, 
        perPage, 
        filterInputs, 
        sortColumn, 
        sortOrder, 
        'filterApply'
      );
    }
  };
  

  
  

  const handlePageChange = async (newPage) => {
    const maxPage = Math.ceil(totalItems / perPage) || 1;
    
    // Validate the new page number
    if (newPage < 1 || newPage > maxPage) {
      // Reset inputPage to current page
      setInputPage(page);
      
      // Set error message
      setPageError(`Please enter a page number between 1 and ${maxPage}.`);
      
      // Clear error message after 3 seconds
      setTimeout(() => setPageError(''), 3000);
      
      return;
    }
  
    // Prevent unnecessary updates if the new page is the same as the current page
    if (newPage === page) return;
  
    // Clear any existing error messages
    setPageError('');
    
    // Set loading context to 'pagination' and isPaginating to true
    setLoadingContext('pagination'); 
    setIsPaginating(true);
  
    // Set the new page
    setPage(newPage);
  
    // Fetch data for the new page with 'pagination' context
    await fetchInitialData(
      selectedClient,
      newPage,
      perPage,
      filters,
      sortColumn,
      sortOrder,
      'pagination'
    );
  };
  
  
  
  

  const handlePerPageChange = (event) => {
    const newPerPage = parseInt(event.target.value, 10);
    
    // Prevent unnecessary state updates
    if (newPerPage === perPage) return;
  
    setLoadingContext('pagination'); // Indicate pagination context
    setIsPaginating(true);           // Set paginating state to true
  
    setPerPage(newPerPage);
    setPage(1);
  };
  
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '34px', // Desired height
      height: '34px',
      borderColor: '#e0e1eb',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#e0e1eb',
      },
      padding: '0px',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '34px',
      padding: '0 8px',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '34px',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: '0 8px',
    }),
    clearIndicator: (provided, state) => ({
      ...provided,
      padding: '0 8px',
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: '#e0e1eb',
    }),
    
    multiValueLabel: (provided, state) => ({
      ...provided,
      padding: '0 6px',
      fontSize: '12px',
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      padding: '0 6px',
      ':hover': {
        backgroundColor: '#c0c0c0',
        color: '#fff',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected
        ? '#fff' // Selected font color
        : state.isFocused
        ? '#292B3D' // Focused font color
        : '#292B3D', // Unselected/regular font color
      backgroundColor: state.isSelected
        ? '#4758EB' // Background for selected
        : state.isFocused
        ? '#E0E1EB' // Background for focused
        : '#fff', // Background for unselected
      fontFamily: 'Lexend Deca, sans-serif',
      fontSize: '12px',
      padding: '8px 12px',
      '&:hover': {
        backgroundColor: '#E0E1EB', // Background when hovered
        color: '#292B3D', // Font color when hovered
      },
    }),
    menu: (provided) => ({
      ...provided,
      fontFamily: 'Lexend Deca, sans-serif',
      zIndex: 1000,
      fontSize: '12px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#292B3D',
      fontFamily: 'Lexend Deca, sans-serif',
      fontSize: '12px',
    }),
  };

  const isImageURL = (url) => {
    if (typeof url !== 'string') return false;
    return(/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i.test(url));
  };
  

  const handleRemoveFilter = (index) => {
    const newFilterInputs = filterInputs.filter((_, i) => i !== index);
    setFilterInputs(newFilterInputs);
  };

  const handleColumnClick = (event, column, columnList) => {
    // Shift-click logic
    if (event.shiftKey && lastClickedColumn && columnList.includes(lastClickedColumn)) {
      // Find indexes
      const start = columnList.indexOf(lastClickedColumn);
      const end = columnList.indexOf(column);
      if (start !== -1 && end !== -1) {
        const [low, high] = [Math.min(start,end), Math.max(start,end)];
        const range = columnList.slice(low, high+1);
        // Add all in range if not selected
        const currentlySelected = selectedColumnItems.slice();
        range.forEach(col => {
          if (!currentlySelected.includes(col)) {
            currentlySelected.push(col);
          }
        });
        setSelectedColumnItems(currentlySelected);
      }
    } else {
      // Normal click toggles single item
      setSelectedColumnItems(prevItems =>
        prevItems.includes(column) ? prevItems.filter(item => item !== column) : [...prevItems, column]
      );
    }
    setLastClickedColumn(column);
  };

  const moveToSelected = () => {
    const allUnselected = [...unselectedColumnsRegular, ...unselectedColumnsPdp];
    const selectedFromUnselected = allUnselected.filter(col => selectedColumnItems.includes(col));

    setSelectedColumns([...selectedColumns, ...selectedFromUnselected]);

    setUnselectedColumnsRegular(unselectedColumnsRegular.filter(col => !selectedColumnItems.includes(col)));
    setUnselectedColumnsPdp(unselectedColumnsPdp.filter(col => !selectedColumnItems.includes(col)));

    setSelectedColumnItems([]);
    setLastClickedColumn(null);
  };

  const moveToUnselected = () => {
    const selectedCols = selectedColumns.filter(col => selectedColumnItems.includes(col));

    const moveBackToRegular = selectedCols.filter(col => col === 'id' || col.startsWith('source_'));
    const moveBackToPdp = selectedCols.filter(col => PDP_COLUMNS.includes(col));

    setSelectedColumns(selectedColumns.filter(col => !selectedColumnItems.includes(col)));

    // Reorder and update the unselected columns
    setUnselectedColumnsRegular(reorderRegularColumns([...unselectedColumnsRegular, ...moveBackToRegular]));
    setUnselectedColumnsPdp(reorderPdpColumns([...unselectedColumnsPdp, ...moveBackToPdp]));

    setSelectedColumnItems([]);
    setLastClickedColumn(null);
  };

  // Drag and drop handlers for selected columns
  const onDragStart = (index, event) => {
    setDraggedColumnIndex(index);
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragOver = (index, event) => {
    event.preventDefault();
    if (draggedColumnIndex === null || draggedColumnIndex === index) return;

    const cols = [...selectedColumns];
    const draggedCol = cols[draggedColumnIndex];
    cols.splice(draggedColumnIndex, 1);
    cols.splice(index, 0, draggedCol);
    setDraggedColumnIndex(index);
    setSelectedColumns(cols);
  };

  const onDragEnd = () => {
    setDraggedColumnIndex(null);
  };

  const displayedColumns = data.length > 0 ? selectedColumns.filter(col => Object.keys(data[0]).includes(col)) : [];

  return (
    <div>
      {/* Top Bar */}
      <div className={styles.topBar}>
        <div className={styles.topBarLeft}>
          <button 
            onClick={() => navigate(isAdmin ? '/admin' : '/client')} 
            className={styles.backButton} 
            title="Back"
          >
            <FaArrowLeft size={20} />
          </button>
          <button 
            onClick={handleLogout} 
            className={styles.logoutButton} 
            title="Logout"
          >
            <FaSignOutAlt size={20} />
          </button>
        </div>
  
        <div className={styles.headerTextContainer}>
          <span className={styles.feedViewerVersion}>Quantum Feed Engine - PDP Generation</span>
          <span className={styles.headerSubtitle}>Elevate Your PDP Game</span>
        </div>
  
        <div className={styles.topBarActions}>
        <Select
          options={linkedClients}
          onChange={handleClientChange}
          value={linkedClients.find(client => client.value === selectedClient) || null}
          className={styles.clientDropdown}
          placeholder={isClientsLoading ? 'Loading clients...' : 'Select Client'}
          isLoading={isClientsLoading}
          isDisabled={
            isClientsLoading || 
            isDataLoading    || 
            refreshStatus === 'loading'  // <-- Disable if refresh is loading
          } // Now disabled while clients or data are loading
          styles={customStyles}
          noOptionsMessage={() => clientError || 'No clients available'}
          isClearable
        />

  
          <button
            className={styles.refreshButton}
            title="Refresh"
            onClick={handleRefresh}
            disabled={isRefreshing}
          >
            {isRefreshing ? (
              <ClipLoader size={20} color="#ffffff" />
            ) : (
              <FaSyncAlt size={20} />
            )}
          </button>
          <button
            className={styles.promptBuilderButton}
            title="Go to Prompt Builder"
            onClick={handleNavigateToPromptBuilder}
          >
            <FaPencilAlt size={20} />
          </button>
        </div>
  
        <img
          src="https://storage.googleapis.com/quantum-feed-engine/workbench/application-images/3_down.png"
          alt="Header"
          className={styles.headerImage}
        />
      </div>
  
      <div className={styles.statusContainer}>

        {/* ====== REFRESH STATES ====== */}
        {refreshStatus === 'loading' && (
          <div className={styles.infoMessage}>
            <ClipLoader size={20} color="#4758EB" style={{ marginRight: '8px' }} />
            <span>Refreshing PDP data...</span>
          </div>
        )}
        {refreshStatus === 'success' && (
          <div className={styles.successMessage}>
            PDP data refreshed successfully.
          </div>
        )}
        {refreshStatus === 'error' && (
          <div className={styles.errorMessage}>
            {refreshError || 'Error refreshing PDP data.'}
          </div>
        )}

        {/* ====== LOAD-DATA STATES ====== */}
        {loadDataStatus === 'loading' && (
          <div className={styles.infoMessage}>
            <ClipLoader 
              size={20} 
              color="#4758EB" 
              style={{ marginRight: '8px' }}
            />
            <span>Loading data, please wait...</span>
          </div>
        )}
        {loadDataStatus === 'success' && (
          <div className={styles.successMessage}>
            Data loaded successfully.
          </div>
        )}
        {loadDataStatus === 'noData' && (
          <div className={styles.warningMessage}>
            No data was found.
          </div>
        )}
        {loadDataStatus === 'error' && (
          <div className={styles.errorMessage}>
            {loadDataError || 'Error loading data.'}
          </div>
        )}

      </div>



      {/* Main Content: Conditionally Rendered Based on Loading State and Data Availability */}
      {(!isDataLoading || loadingContext === 'filterApply' || loadingContext === 'pagination')
      && selectedClient && (
        <>
          {/* Column Selection Section */}
          <div className={styles.columnSection}>
            <div className={styles.listContainer}>
              {/* Regular Columns List */}
              <div className={styles.scrollableListContainer}>
                <h3>Regular Columns</h3>
                <div className={styles.scrollableList}>
                  <ul>
                    {unselectedColumnsRegular.map((col) => {
                      const isSource = isSourceColumn(col);
                      return (
                        <li
                          key={col}
                          className={selectedColumnItems.includes(col) ? styles.selected : ''}
                          onClick={(e) => handleColumnClick(e, col, unselectedColumnsRegular)}
                        >
                          <span className={styles.columnName}>
                            {getDisplayName(col)}
                          </span>
                          {/* Conditionally render the icon */}
                          {isSource ? (
                            <FaDatabase className={styles.sourceIcon} title="Source column" />
                          ) : (
                            <FaMagic className={styles.magicIcon} title="Magic column" />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
  
              {/* PDP Columns List */}
              <div className={styles.scrollableListContainer}>
                <h3>PDP Columns</h3>
                <div className={styles.scrollableList}>
                  <ul>
                    {unselectedColumnsPdp.map((col) => {
                      const isSource = isSourceColumn(col);
                      return (
                        <li
                          key={col}
                          className={selectedColumnItems.includes(col) ? styles.selected : ''}
                          onClick={(e) => handleColumnClick(e, col, unselectedColumnsPdp)}
                        >
                          <span className={styles.columnName}>
                            {getDisplayName(col)}
                          </span>
                          {/* Conditionally render the appropriate icon */}
                          {isSource ? (
                            <FaDatabase className={styles.sourceIcon} title="Source column" />
                          ) : (
                            <FaMagic className={styles.magicIcon} title="Magic column" />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
  
              {/* Button group for moving columns */}
              <div className={styles.buttonGroupColumnSelection}>
                <button
                  onClick={moveToSelected}
                  disabled={
                    selectedColumnItems.length === 0 ||
                    !selectedColumnItems.some(item => unselectedColumnsRegular.includes(item) || unselectedColumnsPdp.includes(item))
                  }
                  title="Add Selected Columns"
                >
                  <FaChevronRight size={20} />
                </button>
                <button
                  onClick={moveToUnselected}
                  disabled={selectedColumnItems.length === 0 || !selectedColumnItems.some(item => selectedColumns.includes(item))}
                  title="Remove Selected Columns"
                >
                  <FaChevronLeft size={20} />
                </button>
              </div>
  
              {/* Selected Columns List */}
              <div className={styles.scrollableListContainer}>
                <h3>Selected Columns</h3>
                <div className={styles.scrollableList}>
                  <ul>
                    {selectedColumns.map((col, index) => {
                      const isSource = isSourceColumn(col);
                      return (
                        <li
                          key={col}
                          className={selectedColumnItems.includes(col) ? styles.selected : ''}
                          onClick={(e) => handleColumnClick(e, col, selectedColumns)}
                          draggable
                          onDragStart={(event) => onDragStart(index, event)}
                          onDragOver={(event) => onDragOver(index, event)}
                          onDrop={onDragEnd}
                          onDragEnd={onDragEnd}
                        >
                          <span className={styles.columnName}>
                            {getDisplayName(col)}
                          </span>
                          {isSource ? (
                            <FaDatabase className={styles.sourceIcon} title="Source column" />
                          ) : (
                            <FaMagic className={styles.magicIcon} title="Magic column" />
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
  
          {/* Filter Section */}
          <div className={styles.filterSection}>
          {filterInputs.map((filter, index) => (
  <div key={index} className={styles.filterRow}>

    {/* === Logic Select or Placeholder === */}
    <select
      className={styles.filterLogicSelect}
      value={index > 0 ? filter.logic : ''}
      onChange={(e) => {
        if (index > 0) {
          const newFilterInputs = [...filterInputs];
          newFilterInputs[index].logic = e.target.value;
          setFilterInputs(newFilterInputs);
        }
      }}
      disabled={index === 0}
    >
      {index > 0 ? (
        <>
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </>
      ) : (
        <option value="" hidden></option>
      )}
    </select>


    <Select
      options={filterOptions}
      value={
        filter.column 
          ? { value: filter.column, label: filter.column }
          : null
      }
      onChange={(selectedOption) => {
        const newFilterInputs = [...filterInputs];
        newFilterInputs[index].column = selectedOption ? selectedOption.value : '';
        setFilterInputs(newFilterInputs);
      }}
      placeholder="Select Column"
      className={styles.filterDropdown}
      isDisabled={data.length === 0}
      noOptionsMessage={() => data.length === 0 ? 'No columns available' : 'No options'}
      components={{
        Option: ColumnOption,
        SingleValue: ColumnSingleValue
      }}
      styles={customStyles}
    />

    {/* === Filter Type === */}
    <select
      className={styles.filterTypeSelect}
      value={filter.type}
      onChange={(e) => {
        const newFilterInputs = [...filterInputs];
        newFilterInputs[index].type = e.target.value;
        setFilterInputs(newFilterInputs);
      }}
    >
      <option value="contains">contains</option>
      <option value="not_contains">doesn't contain</option>
      <option value="equals">equals</option>
      <option value="not_equals">not equals</option>
      <option value="starts_with">starts with</option>
      <option value="not_starts_with">doesn't start with</option>
      <option value="ends_with">ends with</option>
      <option value="not_ends_with">doesn't end with</option>
      <option value="greater_than">greater than</option>
      <option value="less_than">less than</option>
      <option value="greater_or_equal">greater or equal</option>
      <option value="less_or_equal">less or equal</option>
      <option value="length_equals">length equals</option>
      <option value="length_greater">length greater</option>
      <option value="length_less">length less</option>
      <option value="matches_regexp">matches regex</option>
      <option value="not_matches_regexp">doesn't match regex</option>
      <option value="is_blank">is blank</option>
      <option value="is_not_blank">is not blank</option>
      <option value="in">is in (comma/space/NL)</option>
      <option value="not_in">not in (comma/space/NL)</option>
    </select>

    {/* === Filter Input (always present, but disabled if blank/not blank) === */}
    <input
      type="text"
      className={styles.filterInput}
      value={filter.value}
      disabled={['is_blank', 'is_not_blank'].includes(filter.type)}
      onChange={(e) => {
        const newFilterInputs = [...filterInputs];
        newFilterInputs[index].value = e.target.value;
        setFilterInputs(newFilterInputs);
      }}
    />

    {/* === Remove Button === */}
    <button
      className={styles.removeFilterButton}
      onClick={() => handleRemoveFilter(index)}
    >
      <FaTimes />
    </button>
  </div>
))}



            {/* Filter Actions Container */}
            <div className={styles.filterActionsContainer}>
              <button 
                className={styles.addFilterButton} 
                onClick={addFilter} 
                title="Add Filter"
                disabled={loadingContext === 'filterApply'}
              >
                <FaPlus size={16} />
              </button>

              <button 
                className={styles.applyFilterButton} 
                onClick={handleApplyFilter}
                disabled={loadingContext === 'filterApply'}
                title="Apply Filters"
              >
                {loadingContext === 'filterApply' ? (
                  <ClipLoader size={16} color="#4758EB" />
                ) : (
                  <FaPlay size={16} />
                )}
              </button>
            </div>
          </div>
        </>
      )}
  

  {/* Toolbox Section */}
      {(!isDataLoading || loadingContext === 'filterApply' || loadingContext === 'pagination')
        && selectedClient && (
          <div className={styles.toolbox}>
            <div className="button-group">
              <button 
                  className="action-button"
                  onClick={handleBulkActionsClick}
                  disabled={!selectedClient} 
                  title="Bulk Actions"
                  aria-label="Bulk Actions"
                >
                Actions
              </button>
              <button 
                className="publish-button"
                // onClick={handleOpenFeedPopup} 
                // disabled={!selectedClient} 
                title="Export"
                aria-label="Export"
              >
                Export
              </button>
              <button
                className="download-button"
                onClick={handleDownloadAllData}
                disabled={isDownloading || !selectedClient}
                title="Download Data"
                aria-label="Download Data"
              >
                {isDownloading ? (
                  <ClipLoader size={14} color="#ffffff" />
                ) : (
                  <FaDownload />
                )}
              </button>
            </div>
          </div>
        )}

{(!isDataLoading || loadingContext === 'filterApply' || loadingContext === 'pagination')
 && selectedClient && data.length > 0 && (
  <div className={styles.tableContainer}>
    
    {/* Overlay Spinner During Pagination */}
    {loadingContext === 'pagination' && isDataLoading && (
      <div className={styles.tableOverlay}>
        <ClipLoader size={50} color="#4758EB" />
        <p>Loading data, please wait...</p>
      </div>
    )}

    {displayedColumns.length > 0 ? (
      <table>
        <thead>
          <tr>
            {/* === View Column Header (Now First) === */}
            <th className={`${styles.tableHeader} ${styles.viewColumn}`}>
              View
            </th>

            {/* === Select Column Header (Now Second) === */}
            <th className={`${styles.tableHeader} ${styles.selectColumn}`}>
              Select
            </th>

            {/* === Other Dynamic Columns === */}
            {displayedColumns.map((col) => {
              const isSource = isSourceColumn(col);
              return (
                <th key={col} className={styles.tableHeader}>
                  <div className={styles.headerContent}>
                    <span className={styles.headerName}>{getDisplayName(col)}</span>
                    {isSource
                      ? <FaDatabase className={styles.headerIcon} title="Source column" />
                      : <FaMagic className={styles.headerIcon} title="Magic column" />
                    }
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {data.map((row) => {
            const rowId = row.id; 
            const isRowSelected = selectedRows.includes(rowId);

            return (
              <tr key={rowId}>
                {/* === View Column Cell (Now First) === */}
                <td className={styles.viewColumn}>
                  <button
                    className={styles.RowDataPopupButton}
                    onClick={() => handleOpenRowPopup(row)}
                    title="View Row Details"
                  >
                    <FaInfoCircle size={12} />
                  </button>
                </td>

                {/* === Select Column Cell (Now Second) === */}
                <td className={styles.selectColumn}>
                  <input
                    type="checkbox"
                    checked={isRowSelected}
                    onChange={() => handleRowSelect(rowId)}
                  />
                </td>

                {/* === Render the Rest of the Columns === */}
                {displayedColumns.map((col) => {
                  const cellValue = row[col];
                  const isPdpColumn = PDP_COLUMNS.includes(col);

                  // Grab the corresponding _state if it's a PDP column
                  let cellState = null;
                  if (isPdpColumn) {
                    const stateKey = `${col}_state`;
                    cellState = row[stateKey]; // true/false or possibly undefined
                  }

                  // Use our new helper
                  const cellBgColor = getCellBackgroundColor(isRowSelected, isPdpColumn, cellState);

                  return (
                    <td key={col} style={{ backgroundColor: cellBgColor }}>
                      {isImageURL(cellValue) ? (
                        <img src={cellValue} alt={col} className={styles.tableImage} />
                      ) : (
                        cellValue
                      )}
                    </td>
                  );
                })}

              </tr>
            );
          })}
        </tbody>
      </table>
    ) : (
      // "No Data Available" Message
      <div className={styles.noDataContainer}>
        <p className={styles.noDataMessage}>Select columns to preview data.</p>
      </div>
    )}
  </div>
)}


  
      {/* Pagination Controls */}
      {(!isDataLoading || loadingContext === 'filterApply' || loadingContext === 'pagination')
      && selectedClient && data.length > 0 && (
        <div className={styles.tableControls}>
        <button 
          onClick={() => handlePageChange(page - 1)} 
          disabled={page <= 1 || isPaginating} 
          className={styles.navButton}
          title="Previous Page"
        >
          {isPaginating ? <ClipLoader size={6} color="#FFFFFF" /> : <FaChevronLeft />}
        </button>
        
        <span className={styles.pageLabel}>Page:</span>
    
        <input
          type="number"
          className={styles.pageInput}
          value={inputPage}
          onChange={(e) => {
            const newInput = e.target.value;
            // Allow empty input to let the user clear the field before entering a new number
            if (newInput === '') {
              setInputPage('');
              return;
            }
            const newPage = parseInt(newInput, 10);
            if (!isNaN(newPage)) {
              setInputPage(newPage);
            }
          }}
          onBlur={() => {
            // Validate and update the page when the input loses focus
            const newPage = parseInt(inputPage, 10);
            if (!isNaN(newPage)) {
              handlePageChange(newPage);
            } else {
              // Reset to current page if input is invalid
              setInputPage(page);
              setPageError('Invalid page number.');
              setTimeout(() => setPageError(''), 3000); // Clears error after 3 seconds
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              const newPage = parseInt(inputPage, 10);
              if (!isNaN(newPage)) {
                handlePageChange(newPage);
              } else {
                // Reset to current page if input is invalid
                setInputPage(page);
                setPageError('Invalid page number.');
                setTimeout(() => setPageError(''), 3000); // Clears error after 3 seconds
              }
            }
          }}
          disabled={isPaginating} // Disable during pagination
          min="1"
          max={Math.ceil(totalItems / perPage) || 1}
          title="Current Page"
        />

        <span className={styles.paginationTotal}>/ {Math.ceil(totalItems / perPage) || 1} (Total: {totalItems})</span>
      
        <select 
          value={perPage} 
          onChange={handlePerPageChange} 
          className={styles.inputSelect}
          disabled={isPaginating} // Disable during pagination
          title="Page Size"
        >
          <option value={10}>10</option>
          <option value={100}>100</option>
          <option value={500}>500</option>
          <option value={1000}>1000</option>
          <option value={2000}>2000</option>
          <option value={5000}>5000</option>
          <option value={10000}>10000</option>
        </select>
      
        <button 
          onClick={() => handlePageChange(page + 1)} 
          disabled={page >= Math.ceil(totalItems / perPage) || isPaginating} 
          className={styles.navButton}
          title="Next Page"
        >
          {isPaginating ? <ClipLoader size={6} color="#FFFFFF" /> : <FaChevronRight />}
        </button>
      </div>
    )}
      {isRowPopupOpen && rowPopupData && (
        <PopupRowDataPdp
          isOpen={isRowPopupOpen}
          onClose={handleCloseRowPopup}
          rowData={rowPopupData}
          
          // Passing states & callbacks to the popup
          popupSaving={popupSaving}
          saveSuccess={saveSuccess}
          onSave={handleSaveRowChanges}
        />
      )}

     <PopupPdpBulkActions
       isOpen={isBulkActionsPopupOpen}
       onClose={() => setIsBulkActionsPopupOpen(false)}
       token={token}
       clientName={selectedClient}
       selectedCount={selectedRows.length}
       filteredCount={totalItems}         // If totalItems is your “filtered” count
       allCount={totalItemsAll}           // If totalItemsAll is your “full dataset” count
       filters={filters}                  // pass in if needed
       pdpColumns={PDP_COLUMNS}
     />
    </div>


  );  
};

export default PdpGeneration;
