import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ClipLoader from 'react-spinners/ClipLoader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  FaArrowLeft,
  FaPlus,
  FaTimes,
  FaUpload,
  FaChevronDown,
  FaChevronRight,
} from 'react-icons/fa';
import styles from './css/PromptBuilderPdp.module.css';
import PopupCreatePromptCombination from '../popups/PopupCreatePromptCombinationPdp';
import PopupImportDefaultPromptPdp from '../popups/PopupImportDefaultPromptPdp';
import PopupImportPromptCombinationPdp from '../popups/PopupImportPromptCombinationPdp';


const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;

function safeQuillValue(val) {
  if (val === null || val === undefined) {
    return '';
  }
  if (typeof val === 'object') {
    // Convert objects/arrays to some valid string
    return JSON.stringify(val, null, 2);
  }
  // Otherwise just convert to string
  return String(val);
}

/**
 * The base columns we treat as the "output" side of each PDP generation.
 */
const baseColumns = [
  'meta_title',
  'meta_description',
  'h1',
  'h2',
  'intro',
  'description',
  'usp',
  'faq',
  'custom_field_1',
  'custom_field_2',
  'custom_field_3',
  'custom_field_4',
  'custom_field_5',
  'custom_field_6',
  'custom_field_7',
  'custom_field_8',
  'custom_field_9',
  'custom_field_10',
];

function PromptBuilderPdp({ token, isAdmin }) {
  const navigate = useNavigate();

  // --- Client(s) State ---
  const [linkedClients, setLinkedClients] = useState([]);
  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [clientError, setClientError] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  // --- Prompt combination(s) State ---
  const [promptCombinations, setPromptCombinations] = useState([]);
  const [selectedPromptCombination, setSelectedPromptCombination] = useState(null);
  const [isPromptLoading, setIsPromptLoading] = useState(false);
  const [promptError, setPromptError] = useState(null);

  // --- Importing prompts popup ---
  const [isImportPopupOpen, setIsImportPopupOpen] = useState(false);
  const [importPromptType, setImportPromptType] = useState(null);

  const [isImportComboPopupOpen, setIsImportComboPopupOpen] = useState(false);

  // --- Preview Generation ---
  const [previewRowId, setPreviewRowId] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [generationError, setGenerationError] = useState(null);
  const [generatedPreview, setGeneratedPreview] = useState(null); // Full response from generate-pdp-fields

  // --- Columns + JSON Keys State ---
  const [allColumns, setAllColumns] = useState([]);

  // --- Prompt Combination Data State ---
  const [promptCombinationData, setPromptCombinationData] = useState(null);

  // --- A single loading flag for columns+combo data
  const [isLoadingAll, setIsLoadingAll] = useState(false);

  // --- Collapsible text fields state ---
  const [systemPromptExpanded, setSystemPromptExpanded] = useState(true);
  const [imagePromptExpanded, setImagePromptExpanded] = useState(false);
  const [instructionPromptExpanded, setInstructionPromptExpanded] = useState(false);
  const [auditPromptExpanded, setAuditPromptExpanded] = useState(false);

  // --- Popup (Create prompt combination) ---
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  // --- Deletion states ---
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState(null);

  // --- Status / message bar ---
  const [statusMessage, setStatusMessage] = useState(null);
  const [statusType, setStatusType] = useState(null); // 'success' or 'error'

  // --- Track unsaved changes ---
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Few Shot Prompts (array of examples)
  const [fewShotPrompts, setFewShotPrompts] = useState([]);

  // Debounce timers reference
  const debounceTimeoutsRef = useRef({});


  const handleImportedCombo = (importedName) => {
    // Show success message, e.g. "Imported from source => new combination named `importedName`"
    setStatusMessage(`Prompt combination "${importedName}" imported successfully.`);
    setStatusType('success');

    // Refresh the promptCombinations for the currently selected client
    if (selectedClient) {
      axios
        .get(`${API_URL_PDP}/get-prompt-combinations`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: selectedClient },
        })
        .then((res) => {
          if (res.status === 200) {
            const combos = res.data.prompt_combinations.map((c) => ({
              label: c,
              value: c,
            }));
            setPromptCombinations(combos);
          }
        })
        .catch((err) => {
          console.error('Error refreshing combos after import:', err);
          setStatusMessage('Failed to refresh prompt combinations after import.');
          setStatusType('error');
        });
    }
  };

  /* ==============================
     1) FETCH LINKED CLIENTS (once)
  ============================== */
  useEffect(() => {
    const fetchLinkedClients = async () => {
      try {
        setIsClientsLoading(true);
        setClientError(null);
        const response = await axios.get(`${API_URL_PDP}/client-data`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          const clientOptions = response.data.linked_clients.map((client) => ({
            label: client,
            value: client,
          }));
          setLinkedClients(clientOptions);
        } else {
          setClientError('Failed to load clients');
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
        setClientError('An error occurred while loading clients.');
      } finally {
        setIsClientsLoading(false);
      }
    };
    fetchLinkedClients();
  }, [token]);

  /* ==============================
     2) FETCH PROMPT COMBINATIONS 
        (whenever client changes)
  ============================== */
  useEffect(() => {
    if (!selectedClient) {
      setPromptCombinations([]);
      setSelectedPromptCombination(null);
      return;
    }

    const fetchPromptCombinations = async () => {
      try {
        setIsPromptLoading(true);
        setPromptError(null);
        const response = await axios.get(`${API_URL_PDP}/get-prompt-combinations`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: selectedClient },
        });

        if (response.status === 200) {
          const combinations = response.data.prompt_combinations.map((comb) => ({
            label: comb,
            value: comb,
          }));
          setPromptCombinations(combinations);
        } else {
          setPromptError('Failed to load prompt combinations');
        }
      } catch (error) {
        console.error('Error fetching prompt combinations:', error);
        setPromptError('An error occurred while loading prompt combinations.');
      } finally {
        setIsPromptLoading(false);
      }
    };

    fetchPromptCombinations();
  }, [selectedClient, token]);

  /* =================================================
     3) FETCH COLUMNS + PROMPT COMBINATION DATA TOGETHER
        (whenever client or prompt combo changes)
  =================================================== */
  useEffect(() => {
    if (!selectedClient) {
      // No client => clear everything
      setAllColumns([]);
      setPromptCombinationData(null);
      setFewShotPrompts([]);
      return;
    }

    // If we do have a client, we at least fetch columns.
    // If we also have a selectedPromptCombination, we fetch that data in parallel.
    let cancel = false;
    setIsLoadingAll(true);

    (async () => {
      try {
        // 1) Fetch columns
        const columnsPromise = axios.get(`${API_URL_PDP}/column-data`, {
          params: { client_name: selectedClient },
        });

        // 2) Fetch prompt combo data if we have a combo selected, or do a no-op
        let comboPromise;
        if (selectedPromptCombination) {
          comboPromise = axios.get(`${API_URL_PDP}/get-prompt-combination-data`, {
            headers: { Authorization: `Bearer ${token}` },
            params: {
              client_name: selectedClient,
              prompt_combination_name: selectedPromptCombination,
            },
          });
        } else {
          // If no prompt combination is selected, we won't fetch combo data
          comboPromise = Promise.resolve({ data: null });
        }

        // Fire both in parallel
        const [columnsRes, comboRes] = await Promise.all([columnsPromise, comboPromise]);
        if (cancel) return;

        /* ----- Process columns response ----- */
        let mergedColumns = [];
        if (columnsRes.status === 200) {
          const { columns, json_keys } = columnsRes.data;

          // Force "id" first if it exists
          const idColumn = columns.find((col) => col.toLowerCase() === 'id');
          const filteredColumns = idColumn ? [idColumn] : [];
          mergedColumns = Array.from(new Set([...filteredColumns, ...json_keys]));
          mergedColumns.sort();

          setAllColumns(mergedColumns);
        } else {
          setAllColumns([]);
          setStatusMessage('Failed to load columns and JSON keys.');
          setStatusType('error');
        }

        /* ----- Process prompt combination data (if any) ----- */
        const comboData = comboRes?.data;
        if (!comboData) {
          // Means no prompt combination selected, or doc not found => set all columns by default
          setPromptCombinationData({
            selectedColumns: mergedColumns, // Default to all columns
            systemPrompt: '',
            imagePrompt: '',
            instructionPrompt: '',
            auditPromptActivate: false,
            auditPromptText: '',
          });
          setFewShotPrompts([]);
        } else {
          // doc might exist or might be empty
          if (comboRes.status === 200 && comboData) {
            const data = comboData;

            // Load any existing few-shot prompts
            const fewShotsFromBackend = data.few_shot_prompts || [];
            const newFewShots = fewShotsFromBackend.map((shot) => {
              let previewData = null;
              if (shot.input) {
                const newPreviewData = {};
                // ID
                if (typeof shot.input.id !== 'undefined') {
                  newPreviewData.id = shot.input.id;
                }
                // The rest
                Object.keys(shot.input).forEach((colKey) => {
                  if (colKey.toLowerCase() !== 'id') {
                    newPreviewData[`source_${colKey}`] = shot.input[colKey];
                  }
                });
                previewData = newPreviewData;
              }

              return {
                idValue: shot.id_value || '',
                isRichText: typeof shot.is_rich_text !== 'undefined' ? shot.is_rich_text : true,
                baseColumnContents: shot.output || {},
                previewData,
                previewError: null,
                isLoadingPreview: false,
                isExpanded: true,
              };
            });

            // Merge or fallback to the columns we just fetched:
            let finalSelectedCols = [];
            if (data.selected_columns && data.selected_columns.length > 0) {
              finalSelectedCols = data.selected_columns;
            } else {
              // If DB doesn't have any columns selected, default to all columns:
              finalSelectedCols = mergedColumns;
            }

            setPromptCombinationData({
              selectedColumns: finalSelectedCols,
              systemPrompt: data.system_prompt || '',
              imagePrompt: data.image_prompt || '',
              instructionPrompt: data.instruction_prompt || '',
              auditPromptActivate: data.audit_prompt_activate || false,
              auditPromptText: data.audit_prompt_text || '',
            });
            setFewShotPrompts(newFewShots);
          } else {
            // doc doesn't exist => set defaults (all columns)
            setPromptCombinationData({
              selectedColumns: mergedColumns,
              systemPrompt: '',
              imagePrompt: '',
              instructionPrompt: '',
              auditPromptActivate: false,
              auditPromptText: '',
            });
            setFewShotPrompts([]);
          }
        }
      } catch (err) {
        console.error('Error fetching columns/prompt combo:', err);
        setAllColumns([]);
        setPromptCombinationData(null);
        setFewShotPrompts([]);
        setStatusMessage('An error occurred while loading data.');
        setStatusType('error');
      } finally {
        if (!cancel) {
          setIsLoadingAll(false);
        }
      }
    })();

    return () => {
      cancel = true;
    };
  }, [selectedClient, selectedPromptCombination, token]);

  /* ==============================
     HANDLERS: create, remove, import
  ============================== */
  const handleAdd = () => {
    setIsPopupOpen(true);
  };

  const handleCreated = (newPromptName) => {
    console.log('New prompt combination created:', newPromptName);
    setStatusMessage(`Prompt combination "${newPromptName}" created successfully.`);
    setStatusType('success');
    setTimeout(() => {
      setStatusMessage(null);
      setStatusType(null);
    }, 5000);

    if (selectedClient) {
      axios
        .get(`${API_URL_PDP}/get-prompt-combinations`, {
          headers: { Authorization: `Bearer ${token}` },
          params: { client_name: selectedClient },
        })
        .then((response) => {
          if (response.status === 200) {
            const combos = response.data.prompt_combinations.map((comb) => ({
              label: comb,
              value: comb,
            }));
            setPromptCombinations(combos);
            setSelectedPromptCombination(newPromptName);
          }
        })
        .catch((error) => {
          console.error('Error refreshing prompt combos:', error);
          setStatusMessage('Failed to refresh prompt combinations.');
          setStatusType('error');
          setTimeout(() => {
            setStatusMessage(null);
            setStatusType(null);
          }, 5000);
        });
    }
  };

  const handleRemove = async () => {
    if (!selectedPromptCombination) return;
    const confirmDeletion = window.confirm(
      `Are you sure you want to delete the prompt combination "${selectedPromptCombination}"?`
    );
    if (!confirmDeletion) return;

    try {
      setIsDeleting(true);
      setDeleteError(null);

      const response = await axios.delete(`${API_URL_PDP}/delete-prompt-combination`, {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          client_name: selectedClient,
          prompt_combination_name: selectedPromptCombination,
        },
      });

      if (response.status === 200) {
        setPromptCombinations((prev) =>
          prev.filter((comb) => comb.value !== selectedPromptCombination)
        );
        setSelectedPromptCombination(null);
        setPromptCombinationData(null);

        setStatusMessage(
          `Prompt combination "${selectedPromptCombination}" deleted successfully.`
        );
        setStatusType('success');
        setTimeout(() => {
          setStatusMessage(null);
          setStatusType(null);
        }, 5000);
      } else {
        setDeleteError('Failed to delete prompt combination.');
        setStatusMessage('Failed to delete prompt combination.');
        setStatusType('error');
        setTimeout(() => {
          setStatusMessage(null);
          setStatusType(null);
        }, 5000);
      }
    } catch (error) {
      console.error('Error deleting prompt combination:', error);
      const errorMsg =
        error.response?.data?.message ||
        'An error occurred while deleting the prompt combination.';
      setDeleteError(errorMsg);

      setStatusMessage(errorMsg);
      setStatusType('error');
      setTimeout(() => {
        setStatusMessage(null);
        setStatusType(null);
      }, 5000);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleImport = () => {
    // If you have a global import logic, place it here
    console.log('Import clicked (global prompt import?)');
  };

  /* ==============================
     NAVIGATION
  ============================== */
  const handleBack = () => {
    navigate('/pdp-generation');
  };

  /* ==============================
     SELECT HANDLERS
  ============================== */
  const handleSelectClient = (option) => {
    setSelectedClient(option ? option.value : null);
    setSelectedPromptCombination(null);
    setAllColumns([]);
    setPromptCombinationData(null);
    setStatusMessage(null);
    setStatusType(null);
    setHasUnsavedChanges(false);
    setFewShotPrompts([]);
    setGeneratedPreview(null);
  };

  const handleSelectPromptCombination = (option) => {
    setSelectedPromptCombination(option ? option.value : null);
    setStatusMessage(null);
    setStatusType(null);
    setFewShotPrompts([]);
    setGeneratedPreview(null);
  };

  const handleMultiSelectColumns = (selectedOptions) => {
    const newValues = selectedOptions ? selectedOptions.map((opt) => opt.value) : [];
    setPromptCombinationData((prev) => ({
      ...prev,
      selectedColumns: newValues,
    }));
    setHasUnsavedChanges(true);
  };

  /* ==============================
     PROMPT TEXT FIELD HANDLERS
  ============================== */
  const handleChangeSystemPrompt = (e) => {
    setPromptCombinationData((prev) => ({
      ...prev,
      systemPrompt: e.target.value,
    }));
    setHasUnsavedChanges(true);
  };
  const handleChangeImagePrompt = (e) => {
    setPromptCombinationData((prev) => ({
      ...prev,
      imagePrompt: e.target.value,
    }));
    setHasUnsavedChanges(true);
  };
  const handleChangeInstructionPrompt = (e) => {
    setPromptCombinationData((prev) => ({
      ...prev,
      instructionPrompt: e.target.value,
    }));
    setHasUnsavedChanges(true);
  };

  const handleImportSystem = (e) => {
    e.stopPropagation();
    setImportPromptType('system');
    setIsImportPopupOpen(true);
  };
  const handleImportImage = (e) => {
    e.stopPropagation();
    setImportPromptType('image');
    setIsImportPopupOpen(true);
  };
  const handleImportInstruction = (e) => {
    e.stopPropagation();
    setImportPromptType('instruction');
    setIsImportPopupOpen(true);
  };

  const handleImportPromptText = (importedText) => {
    setHasUnsavedChanges(true);
    if (importPromptType === 'system') {
      setPromptCombinationData((prev) => ({ ...prev, systemPrompt: importedText }));
    } else if (importPromptType === 'image') {
      setPromptCombinationData((prev) => ({ ...prev, imagePrompt: importedText }));
    } else if (importPromptType === 'instruction') {
      setPromptCombinationData((prev) => ({ ...prev, instructionPrompt: importedText }));
    }
  };

  const toggleSystemPrompt = () => setSystemPromptExpanded((prev) => !prev);
  const toggleImagePrompt = () => setImagePromptExpanded((prev) => !prev);
  const toggleInstructionPrompt = () => setInstructionPromptExpanded((prev) => !prev);

  // Audit Prompt Toggles/Handlers
  const toggleAuditPrompt = () => setAuditPromptExpanded((prev) => !prev);

  const handleToggleAuditActivate = () => {
    setPromptCombinationData((prev) => ({
      ...prev,
      auditPromptActivate: !prev.auditPromptActivate,
    }));
    setHasUnsavedChanges(true);
  };

  const handleChangeAuditPromptText = (e) => {
    setPromptCombinationData((prev) => ({
      ...prev,
      auditPromptText: e.target.value,
    }));
    setHasUnsavedChanges(true);
  };

  /* ==============================
     FEW SHOT PROMPTS HANDLERS
  ============================== */
  const createEmptyBaseColumnContents = () => {
    const obj = {};
    baseColumns.forEach((col) => {
      obj[col] = '';
    });
    return obj;
  };

  const handleAddFewShotPrompt = () => {
    const newPrompt = {
      idValue: '',
      isRichText: true,
      baseColumnContents: createEmptyBaseColumnContents(),
      previewData: null,
      previewError: null,
      isLoadingPreview: false,
      isExpanded: true,
    };
    setFewShotPrompts((prev) => [...prev, newPrompt]);
    setHasUnsavedChanges(true);
  };

  const handleRemoveFewShotPrompt = (index) => {
    if (debounceTimeoutsRef.current[index]) {
      clearTimeout(debounceTimeoutsRef.current[index]);
      delete debounceTimeoutsRef.current[index];
    }
    setFewShotPrompts((prev) => prev.filter((_, i) => i !== index));
    setHasUnsavedChanges(true);
  };

  const handleToggleRichText = (index) => {
    setFewShotPrompts((prev) =>
      prev.map((prompt, i) => {
        if (i === index) {
          return { ...prompt, isRichText: !prompt.isRichText };
        }
        return prompt;
      })
    );
    setHasUnsavedChanges(true);
  };

  const handleFewShotIdChange = (index, rawVal) => {
    const newVal = rawVal.trim();
    setFewShotPrompts((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        idValue: newVal,
        previewData: null,
        previewError: null,
        isLoadingPreview: newVal !== '',
      };
      return updated;
    });
    setHasUnsavedChanges(true);

    if (debounceTimeoutsRef.current[index]) {
      clearTimeout(debounceTimeoutsRef.current[index]);
    }

    if (!newVal) return;
    debounceTimeoutsRef.current[index] = setTimeout(() => {
      fetchPreviewData(index, newVal);
    }, 1000);
  };

  const fetchPreviewData = async (index, idVal) => {
    try {
      setFewShotPrompts((prev) => {
        const updated = [...prev];
        if (updated[index]) {
          updated[index].isLoadingPreview = true;
          updated[index].previewError = null;
          updated[index].previewData = null;
        }
        return updated;
      });

      const res = await axios.get(`${API_URL_PDP}/fetch-pdp-row`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          client_name: selectedClient,
          row_id: idVal,
        },
      });

      if (res.status === 200 && res.data) {
        setFewShotPrompts((prev) => {
          const updated = [...prev];
          if (updated[index]) {
            updated[index].previewData = res.data;
            updated[index].previewError = null;
            updated[index].isLoadingPreview = false;
          }
          return updated;
        });
      }
    } catch (error) {
      console.error('Error fetching preview data:', error);
      let errMsg = 'Error fetching data.';
      if (error.response && error.response.status === 404) {
        errMsg = error.response.data?.message || `No data found for ID="${idVal}".`;
      }
      setFewShotPrompts((prev) => {
        const updated = [...prev];
        if (updated[index]) {
          updated[index].previewData = null;
          updated[index].previewError = errMsg;
          updated[index].isLoadingPreview = false;
        }
        return updated;
      });
    }
  };

  const handleImportFewShot = (index) => {
    const prompt = fewShotPrompts[index];
    if (prompt.previewData) {
      setFewShotPrompts((prev) =>
        prev.map((p, i) => {
          if (i === index) {
            return {
              ...p,
              baseColumnContents: { ...p.baseColumnContents, ...prompt.previewData },
            };
          }
          return p;
        })
      );
      setHasUnsavedChanges(true);
    } else {
      setFewShotPrompts((prev) =>
        prev.map((p, i) => {
          if (i === index) {
            return { ...p, previewError: 'No data available to import.' };
          }
          return p;
        })
      );
    }
  };

  const handleBaseColumnChange = (promptIndex, colName, newValue) => {
    setFewShotPrompts((prev) =>
      prev.map((prompt, i) => {
        if (i !== promptIndex) return prompt;
        return {
          ...prompt,
          baseColumnContents: {
            ...prompt.baseColumnContents,
            [colName]: newValue,
          },
        };
      })
    );
    setHasUnsavedChanges(true);
  };

  const handleToggleFewShotExpand = (index, e) => {
    // Prevent toggling if user clicked on a button
    if (e && e.target && e.target.closest('button')) {
      return;
    }
    setFewShotPrompts((prev) =>
      prev.map((prompt, i) => {
        if (i === index) {
          return { ...prompt, isExpanded: !prompt.isExpanded };
        }
        return prompt;
      })
    );
  };

  /* ==============================
     SAVE CHANGES
  ============================== */
  const handleSave = async () => {
    if (!selectedClient || !selectedPromptCombination || !promptCombinationData) return;

    try {
      // Build a "few_shot_prompts" structure
      const fewShotDataToSave = fewShotPrompts.map((prompt) => {
        let inputObj = {};
        if (prompt.previewData && promptCombinationData.selectedColumns) {
          promptCombinationData.selectedColumns.forEach((col) => {
            if (col.toLowerCase() === 'id') {
              inputObj['id'] = prompt.previewData.id || '';
            } else {
              const val = prompt.previewData[`source_${col}`];
              inputObj[col] = val !== undefined ? val : '';
            }
          });
        }

        return {
          id_value: prompt.idValue,
          is_rich_text: prompt.isRichText,
          input: inputObj,
          output: prompt.baseColumnContents,
        };
      });

      // Save to backend
      const response = await axios.put(
        `${API_URL_PDP}/update-prompt-combination`,
        {
          client_name: selectedClient,
          prompt_combination_name: selectedPromptCombination,
          selected_columns: promptCombinationData.selectedColumns,
          system_prompt: promptCombinationData.systemPrompt,
          image_prompt: promptCombinationData.imagePrompt,
          instruction_prompt: promptCombinationData.instructionPrompt,
          few_shot_prompts: fewShotDataToSave,
          audit_prompt_activate: promptCombinationData.auditPromptActivate,
          audit_prompt_text: promptCombinationData.auditPromptText,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        setHasUnsavedChanges(false);
        setStatusMessage('Prompt configuration saved successfully.');
        setStatusType('success');
        setTimeout(() => {
          setStatusMessage(null);
          setStatusType(null);
        }, 5000);
      } else {
        throw new Error('Failed to save prompt configuration.');
      }
    } catch (err) {
      console.error('Error saving prompt configuration:', err);
      setStatusMessage('Error saving prompt configuration.');
      setStatusType('error');
      setTimeout(() => {
        setStatusMessage(null);
        setStatusType(null);
      }, 5000);
    }
  };

  /* ==============================
     GENERATE PREVIEW
  ============================== */
  const handleGeneratePreview = async () => {
    if (!selectedClient || !selectedPromptCombination || !promptCombinationData) return;

    try {
      setIsGenerating(true);
      setGenerationError(null);
      setGeneratedPreview(null);

      // Construct the "few_shot_prompts" array
      const fewShotDataToSend = fewShotPrompts.map((prompt) => {
        let inputObj = {};
        if (prompt.previewData && promptCombinationData.selectedColumns) {
          promptCombinationData.selectedColumns.forEach((col) => {
            if (col.toLowerCase() === 'id') {
              inputObj['id'] = prompt.previewData.id || '';
            } else {
              const val = prompt.previewData[`source_${col}`];
              inputObj[col] = val !== undefined ? val : '';
            }
          });
        }
        return {
          id_value: prompt.idValue,
          is_rich_text: prompt.isRichText,
          input: inputObj,
          output: prompt.baseColumnContents,
        };
      });

      const selectedIdsArray = previewRowId.trim() ? [previewRowId.trim()] : [];

      const response = await axios.post(
        `${API_URL_PDP}/generate-pdp-fields`,
        {
          client_name: selectedClient,
          prompt_combination_name: selectedPromptCombination,
          selected_columns: promptCombinationData.selectedColumns,
          system_prompt: promptCombinationData.systemPrompt,
          image_prompt: promptCombinationData.imagePrompt,
          instruction_prompt: promptCombinationData.instructionPrompt,
          few_shot_prompts: fewShotDataToSend,
          audit_prompt_activate: promptCombinationData.auditPromptActivate,
          audit_prompt_text: promptCombinationData.auditPromptText,
          selected_ids: selectedIdsArray,
          generation_scope: 'prompt_preview',
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setGeneratedPreview(response.data);
    } catch (err) {
      console.error('Error generating preview:', err);
      setGenerationError(err.response?.data?.message || 'Failed to generate preview.');
    } finally {
      setIsGenerating(false);
    }
  };

  /* ==============================
     IMPORT AS FEW SHOT
  ============================== */
  const handleImportAsNewFewShot = (item) => {
    if (!item) return;

    // item: { id, columns_used, prompt_input_data, generated_data }
    // 1) Derive an ID
    const rowId = item.prompt_input_data?.id || item.id || '';

    // 2) Build an "input" object from item.prompt_input_data for the selected columns
    let inputObj = {};
    if (promptCombinationData?.selectedColumns?.length > 0) {
      promptCombinationData.selectedColumns.forEach((col) => {
        if (col.toLowerCase() === 'id') {
          inputObj['id'] = rowId;
        } else {
          inputObj[col] = item.prompt_input_data?.[col] ?? '';
        }
      });
    }

    // 3) Build "output" object from the generated_data for the base columns
    let outputObj = {};
    baseColumns.forEach((col) => {
      outputObj[col] = item.generated_data?.[col] ?? '';
    });

    // 4) Construct a new few-shot prompt
    const newFewShotPrompt = {
      idValue: rowId,
      isRichText: true,
      previewData: null,
      previewError: null,
      isLoadingPreview: false,
      isExpanded: true,
      baseColumnContents: outputObj, // The "output" side
    };

    // 5) Store the "input" side in previewData
    newFewShotPrompt.previewData = {};
    newFewShotPrompt.previewData.id = rowId;
    Object.keys(inputObj).forEach((col) => {
      if (col.toLowerCase() === 'id') return;
      newFewShotPrompt.previewData[`source_${col}`] = inputObj[col];
    });

    // 6) Append the new few-shot to the list
    setFewShotPrompts((prev) => [...prev, newFewShotPrompt]);
    setHasUnsavedChanges(true);

    setStatusMessage(`Imported preview for ID=${rowId} as a new few-shot example.`);
    setStatusType('success');
    setTimeout(() => {
      setStatusMessage(null);
      setStatusType(null);
    }, 5000);
  };

  /* ==============================
     REACT-SELECT Custom Styles
  ============================== */
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: '34px',
      borderColor: '#e0e1eb',
      boxShadow: 'none',
      '&:hover': { borderColor: '#e0e1eb' },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '34px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '0 8px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#292B3D',
      backgroundColor: state.isSelected
        ? '#4758EB'
        : state.isFocused
        ? '#E0E1EB'
        : '#fff',
      padding: '8px 12px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#4758EB',
      color: '#fff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      ':hover': {
        backgroundColor: '#3947c2',
        color: '#fff',
      },
    }),
  };

  /* ==============================
     RENDER
  ============================== */
  return (
    <div className={styles.container}>
      {/* Left Pane */}
      <div className={styles.leftPane}>
        <div className={styles.leftPaneContent}>
          {/* Top Row */}
          <div className={styles.topRow}>
            <button
              className={styles.backButton}
              onClick={handleBack}
              title="Back to PDP Generation"
            >
              <FaArrowLeft size={20} />
            </button>
            <h1 className={styles.pageTitle}>PDP Prompt Builder</h1>
          </div>

          {/* Client / Prompt Combination Row */}
          <div className={styles.dropdownRow}>
            {/* Left: Select Client */}
            <div className={styles.clientColumn}>
              <label className={styles.dropdownLabel}>Select Client</label>
              <Select
                styles={customSelectStyles}
                options={linkedClients}
                value={linkedClients.find((c) => c.value === selectedClient) || null}
                onChange={handleSelectClient}
                placeholder={isClientsLoading ? 'Loading...' : 'Choose Client'}
                isLoading={isClientsLoading}
                isClearable
                noOptionsMessage={() => clientError || 'No clients found'}
              />
            </div>

            {/* Right: Prompt Combination */}
            <div className={styles.promptColumn}>
              <div className={styles.promptAndButtons}>
                <div className={styles.promptDropdownContainer}>
                  <label className={styles.dropdownLabel}>Select Prompt Combination</label>
                  <Select
                    styles={customSelectStyles}
                    options={promptCombinations}
                    value={
                      promptCombinations.find((p) => p.value === selectedPromptCombination) ||
                      null
                    }
                    onChange={handleSelectPromptCombination}
                    placeholder={
                      isPromptLoading
                        ? 'Loading...'
                        : selectedClient
                        ? 'Choose Combination'
                        : 'Select a client first'
                    }
                    isClearable
                    isDisabled={!selectedClient || isPromptLoading}
                    isLoading={isPromptLoading}
                    noOptionsMessage={() => promptError || 'No prompt combinations found'}
                  />
                </div>
                <div className={styles.iconButtons}>
                  <button
                    className={styles.addButton}
                    onClick={handleAdd}
                    title="Add"
                    disabled={!selectedClient}
                  >
                    <FaPlus size={16} />
                  </button>

                {/* Import button - new */}
                <button
                  className={styles.importButton}
                  onClick={() => setIsImportComboPopupOpen(true)}
                  title="Import from Another Client"
                  disabled={!selectedClient}
                >
                  <FaUpload size={16} />
                </button>

                {/* Delete button */}
                <button
                  className={styles.removeButton}
                  onClick={handleRemove}
                  title="Remove"
                  disabled={!selectedPromptCombination || isDeleting}
                >
                  {isDeleting ? <ClipLoader size={6} color="#fff" /> : <FaTimes size={16} />}
                </button>
              </div>
            </div>
            </div>
          </div>

          {/* Status/Message Bar */}
          {statusMessage && (
            <div
              className={`${styles.statusBar} ${
                statusType === 'success' ? styles.success : styles.error
              }`}
            >
              {statusMessage}
            </div>
          )}

          {/* Error Message Display */}
          {(clientError || promptError || deleteError) && (
            <div className={styles.errorMessage}>
              {clientError || promptError || deleteError}
            </div>
          )}

          {/* If a client is chosen, either we show "loading" or the final config */}
          {selectedClient && (
            isLoadingAll ? (
              <div className={styles.loadingContainer}>
                <ClipLoader size={50} color="#4758EB" />
                <p>Loading prompt configuration...</p>
              </div>
            ) : (
              // Once done loading columns + (optionally) prompt combo data:
              <>
                {selectedPromptCombination && promptCombinationData ? (
                  <>
                    {/* Multi-select for columns */}
                    <div className={styles.section}>
                      <label className={styles.sectionLabel}>Columns / JSON Keys to Include</label>
                      <Select
                        className={styles.columnsMultiSelect}
                        isMulti
                        closeMenuOnSelect={false}
                        styles={customSelectStyles}
                        options={allColumns.map((c) => ({ label: c, value: c }))}
                        value={
                          promptCombinationData.selectedColumns
                            .filter((c) => allColumns.includes(c))
                            .map((c) => ({ label: c, value: c }))
                        }
                        onChange={handleMultiSelectColumns}
                        placeholder="Select columns or JSON keys"
                        noOptionsMessage={() => 'No columns/keys found'}
                      />
                    </div>

                    {/* Collapsible: System Prompt */}
                    <div className={styles.promptContainer}>
                      <div className={styles.promptHeader} onClick={toggleSystemPrompt}>
                        <h3 className={styles.promptHeaderTitle}>
                          {systemPromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                          System Prompt
                        </h3>
                        <div className={styles.promptHeaderActions}>
                          <button
                            className={styles.importButton}
                            onClick={handleImportSystem}
                            title="Import System Prompt"
                          >
                            <FaUpload size={14} />
                          </button>
                        </div>
                      </div>
                      {systemPromptExpanded && (
                        <div className={styles.promptBody}>
                          <textarea
                            className={styles.textAreaPrompt}
                            value={promptCombinationData.systemPrompt}
                            onChange={handleChangeSystemPrompt}
                          />
                        </div>
                      )}
                    </div>

                    {/* Collapsible: Image Prompt */}
                    <div className={styles.promptContainer}>
                      <div className={styles.promptHeader} onClick={toggleImagePrompt}>
                        <h3 className={styles.promptHeaderTitle}>
                          {imagePromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                          Image Prompt
                        </h3>
                        <div className={styles.promptHeaderActions}>
                          <button
                            className={styles.importButton}
                            onClick={handleImportImage}
                            title="Import Image Prompt"
                          >
                            <FaUpload size={14} />
                          </button>
                        </div>
                      </div>
                      {imagePromptExpanded && (
                        <div className={styles.promptBody}>
                          <textarea
                            className={styles.textAreaPrompt}
                            value={promptCombinationData.imagePrompt}
                            onChange={handleChangeImagePrompt}
                          />
                        </div>
                      )}
                    </div>

                    {/* Collapsible: Instruction Prompt */}
                    <div className={styles.promptContainer}>
                      <div className={styles.promptHeader} onClick={toggleInstructionPrompt}>
                        <h3 className={styles.promptHeaderTitle}>
                          {instructionPromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                          Instruction Prompt
                        </h3>
                        <div className={styles.promptHeaderActions}>
                          <button
                            className={styles.importButton}
                            onClick={handleImportInstruction}
                            title="Import Instruction Prompt"
                          >
                            <FaUpload size={14} />
                          </button>
                        </div>
                      </div>
                      {instructionPromptExpanded && (
                        <div className={styles.promptBody}>
                          <textarea
                            className={styles.textAreaPrompt}
                            value={promptCombinationData.instructionPrompt}
                            onChange={handleChangeInstructionPrompt}
                          />
                        </div>
                      )}
                    </div>

                    {/* Few Shot Prompts Section */}
                    <div className={styles.fewShotSection}>
                      <div className={styles.fewShotSectionHeader}>
                        <h3 className={styles.fewShotSectionTitle}>Few Shot Prompts</h3>
                        <button
                          className={styles.addFewShotButton}
                          onClick={handleAddFewShotPrompt}
                          title="Add Few Shot Prompt"
                        >
                          <FaPlus size={16} />
                          <span>Add Few Shot</span>
                        </button>
                      </div>

                      <p className={styles.fewShotSectionDescription}>
                        Provide example data ("few shots") that QFE can learn from when generating PDP content.
                      </p>

                      <div className={styles.fewShotList}>
                        {fewShotPrompts.map((prompt, index) => (
                          <div className={styles.fewShotPromptContainer} key={`fewshot-${index}`}>
                            {/* Header (click to expand/collapse) */}
                            <div
                              className={styles.fewShotPromptHeader}
                              onClick={(e) => handleToggleFewShotExpand(index, e)}
                            >
                              <h4 className={styles.fewShotPromptTitle}>
                                {prompt.isExpanded ? <FaChevronDown /> : <FaChevronRight />}
                                Few Shot Example #{index + 1}
                              </h4>
                              <div className={styles.fewShotPromptActions}>
                                <button
                                  className={styles.removeFewShotButton}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleRemoveFewShotPrompt(index);
                                  }}
                                  title="Remove this example"
                                >
                                  <FaTimes size={14} />
                                </button>
                              </div>
                            </div>

                            {/* Body (collapsible) */}
                            {prompt.isExpanded && (
                              <div className={styles.fewShotPromptBody}>
                                {/* Row with ID input, import button, toggle RTE */}
                                <div className={styles.fewShotPromptRow}>
                                  <div className={styles.fewShotPromptIdGroup}>
                                    <label>ID:</label>
                                    <input
                                      className={styles.fewShotPromptInput}
                                      placeholder="Insert ID"
                                      value={prompt.idValue}
                                      onChange={(e) => handleFewShotIdChange(index, e.target.value)}
                                    />
                                  </div>

                                  <div className={styles.fewShotPromptButtons}>
                                    {/* Rich Text Toggle */}
                                    <label className={styles.toggleSwitch}>
                                      <input
                                        type="checkbox"
                                        checked={prompt.isRichText}
                                        onChange={() => handleToggleRichText(index)}
                                        aria-label={`Toggle Rich Text for Example ${index + 1}`}
                                      />
                                      <span className={styles.slider}></span>
                                    </label>
                                    <span>{prompt.isRichText ? 'Rich Text' : 'Plain Text'}</span>
                                  </div>
                                </div>

                                {/* Data Preview Box */}
                                <div className={styles.fewShotPromptScrollBox}>
                                  {prompt.isLoadingPreview ? (
                                    <p>Loading data for ID: {prompt.idValue}...</p>
                                  ) : prompt.previewError ? (
                                    <p className={styles.errorPreview}>{prompt.previewError}</p>
                                  ) : prompt.previewData ? (
                                    <>
                                      <p><strong>Preview for ID: {prompt.idValue}</strong></p>
                                      {promptCombinationData.selectedColumns.length > 0 ? (
                                        <div className={styles.previewTable}>
                                          {promptCombinationData.selectedColumns.map((col) => {
                                            const val = getPreviewValue(prompt.previewData, col);
                                            return (
                                              <div className={styles.previewRow} key={col}>
                                                <div className={styles.previewLabel}>{col}</div>
                                                <div className={styles.previewValue}>
                                                  {val !== undefined && val !== null
                                                    ? String(val)
                                                    : '[empty]'}
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      ) : (
                                        <p>No columns selected.</p>
                                      )}
                                    </>
                                  ) : (
                                    <p>Enter an ID above to see a preview.</p>
                                  )}
                                </div>

                                {/* Base Columns Grid (Rich Text vs Plain Text) */}
                                <div className={styles.fewShotColumnsGrid}>
                                  {baseColumns.map((col) => (
                                    <div key={col} className={styles.fewShotColumnField}>
                                      <label className={styles.fewShotColumnLabel}>{col}</label>
                                      {prompt.isRichText ? (
                                        <ReactQuill
                                          theme="snow"
                                          className={styles.richTextEditorQuill}
                                          value={safeQuillValue(prompt.baseColumnContents[col])}
                                          onChange={(html) =>
                                            handleBaseColumnChange(index, col, html)
                                          }
                                        />
                                      ) : (
                                        <textarea
                                          className={styles.textAreaFewShotPrompt}
                                          placeholder={`Plain text for ${col}`}
                                          value={prompt.baseColumnContents[col]}
                                          onChange={(e) =>
                                            handleBaseColumnChange(index, col, e.target.value)
                                          }
                                        />
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* AUDIT PROMPT SECTION */}
                    <div className={styles.auditPromptSection}>
                      <div
                        className={styles.auditPromptHeader}
                        onClick={toggleAuditPrompt}
                      >
                        <h3 className={styles.auditPromptHeaderTitle}>
                          {auditPromptExpanded ? <FaChevronDown /> : <FaChevronRight />}
                          Audit Prompt (ADVANCED)
                        </h3>
                      </div>
                      {auditPromptExpanded && (
                        <div className={styles.auditPromptBody}>
                          <p className={styles.auditPromptDescription}>
                            This prompt can be used for auditing or modifying the final output. It will be applied AFTER everything is generated.
                          </p>

                          <div className={styles.auditPromptToggleRow}>
                            <label className={styles.toggleSwitch}>
                              <input
                                type="checkbox"
                                checked={promptCombinationData.auditPromptActivate}
                                onChange={handleToggleAuditActivate}
                                aria-label="Toggle Audit Prompt Activation"
                              />
                              <span className={styles.slider}></span>
                            </label>
                            <span className={styles.auditPromptToggleLabel}>
                              {promptCombinationData.auditPromptActivate ? 'Active' : 'Inactive'}
                            </span>
                          </div>

                          <textarea
                            className={styles.textAreaPrompt}
                            style={{ minHeight: '100px' }}
                            placeholder="Any instructions or notes for the audit?"
                            value={promptCombinationData.auditPromptText}
                            onChange={handleChangeAuditPromptText}
                          />
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  // No prompt combination selected => just show instructions
                  <div className={styles.noCombinationSelected}>
                    <p>Please select (or create) a Prompt Combination to configure prompts.</p>
                  </div>
                )}
              </>
            )
          )}
        </div>

        {/* Sticky Save Bar */}
        {hasUnsavedChanges && selectedClient && selectedPromptCombination && promptCombinationData && (
          <div className={styles.stickyUnsavedBar}>
            <div className={styles.stickyUnsavedText}>You have unsaved changes.</div>
            <button className={styles.saveButton} onClick={handleSave}>
              Save
            </button>
          </div>
        )}
      </div>

      {/* Right Pane */}
      <div className={styles.rightPane}>
        <h2 className={styles.rightPaneHeader}>PDP Prompt Preview</h2>

        {/* ID Input and Generate Preview Button */}
        <div className={styles.previewInputRow}>
          <input
            type="text"
            placeholder="Enter ID or leave empty for the first ID"
            value={previewRowId}
            onChange={(e) => setPreviewRowId(e.target.value)}
            className={styles.previewInput}
          />
          <button
            onClick={handleGeneratePreview}
            className={styles.generatePreviewButton}
            disabled={
              !selectedClient ||
              !selectedPromptCombination ||
              !promptCombinationData ||
              isGenerating
            }
          >
            {isGenerating ? 'Generating...' : 'Generate Preview'}
          </button>
        </div>

        {/* Error Message */}
        {generationError && (
          <div className={styles.previewErrorMessage}>{generationError}</div>
        )}

        {/* Loading State */}
        {isGenerating && (
          <div className={styles.rightPaneLoading}>
            <ClipLoader size={50} color="#4758EB" />
            <p>Generating preview...</p>
          </div>
        )}

        {/* ========== ITEM PREVIEW ========== */}
        {generatedPreview && generatedPreview.generated_item_info && (
          <ItemPreview item={generatedPreview.generated_item_info} />
        )}

        {/* Comparison-based Preview (Side-by-side) */}
        {generatedPreview && (
          <ComparisonDisplay
            batchUpdates={generatedPreview.batch_updates}
            baseColumns={baseColumns}
            onImportPreviewAsFewShot={handleImportAsNewFewShot}
          />
        )}
      </div>

      {/* Popup create prompt combination */}
      <PopupCreatePromptCombination
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        selectedClient={selectedClient}
        token={token}
        onCreated={handleCreated}
      />

      {/* Popup import default prompts */}
      <PopupImportDefaultPromptPdp
        isOpen={isImportPopupOpen}
        onClose={() => setIsImportPopupOpen(false)}
        promptType={importPromptType}
        token={token}
        isAdmin={isAdmin}
        onImport={handleImportPromptText}
      />

    <PopupImportPromptCombinationPdp
        isOpen={isImportComboPopupOpen}
        onClose={() => setIsImportComboPopupOpen(false)}
        linkedClients={linkedClients}  // same array used for the main client dropdown
        destinationClient={selectedClient}
        token={token}
        onImported={handleImportedCombo}
      />
    
    </div>
  );
}

/* ===================================
   Subcomponents and Utilities
==================================== */

/**
 * Given previewData, returns the corresponding value for the given column.
 */
function getPreviewValue(previewData, col) {
  if (!previewData) return undefined;
  if (col.toLowerCase() === 'id') {
    return previewData.id;
  }
  const sourceKey = `source_${col}`;
  return previewData[sourceKey];
}

/**
 * Displays comparison data for generated PDP fields in a list.
 */
function ComparisonDisplay({ batchUpdates, baseColumns, onImportPreviewAsFewShot }) {
  if (!batchUpdates || batchUpdates.length === 0) {
    return <p>No comparison data to display.</p>;
  }

  return (
    <div style={{ width: '100%' }}>
      {batchUpdates.map((item, index) => (
        <ComparisonItem
          key={index}
          item={item}
          baseColumns={baseColumns}
          onImportPreviewAsFewShot={onImportPreviewAsFewShot}
        />
      ))}
    </div>
  );
}

/**
 * A single comparison item showing the input data and generated data side-by-side.
 */
function ComparisonItem({ item, baseColumns, onImportPreviewAsFewShot }) {
  const [isExpanded, setIsExpanded] = React.useState(true);
  const [isRichText, setIsRichText] = React.useState(true);

  const { id, columns_used, prompt_input_data, generated_data } = item;

  // Sort generated keys according to the baseColumns order + leftover columns
  const generatedKeys = generated_data ? Object.keys(generated_data) : [];
  const sortedGeneratedKeysInBaseColsOrder = baseColumns.filter((col) =>
    generatedKeys.includes(col)
  );
  const leftoverGeneratedKeys = generatedKeys.filter((key) => !baseColumns.includes(key));
  const finalGeneratedKeys = [...sortedGeneratedKeysInBaseColsOrder, ...leftoverGeneratedKeys];

  // For input data, we’ll just preserve their natural order or you can sort if desired
  const inputKeys = prompt_input_data ? Object.keys(prompt_input_data) : [];

  const toggleExpand = () => setIsExpanded((prev) => !prev);
  const handleToggleRichText = () => setIsRichText((prev) => !prev);
  

  return (
    <div className={styles.comparisonSection}>
      {/* Header */}
      <div className={styles.comparisonHeader} onClick={toggleExpand}>
        <h3 className={styles.comparisonHeaderTitle}>
          {isExpanded ? <FaChevronDown /> : <FaChevronRight />}
          {`ID: ${id || 'N/A'}`}
        </h3>

        {/* Actions row (stopPropagation so click doesn't collapse) */}
        <div className={styles.comparisonHeaderActions} onClick={(e) => e.stopPropagation()}>
          <div className={styles.richTextToggleContainer}>
            <span className={styles.richTextToggleLabelRightPane}>
              {isRichText ? 'Rich Text' : 'Plain Text'}
            </span>
            <label className={styles.toggleSwitchComparison}>
              <input
                type="checkbox"
                checked={isRichText}
                onChange={handleToggleRichText}
              />
              <span className={styles.sliderComparison}></span>
            </label>
          </div>
          <button
            className={styles.importFewShotButtonRightPane}
            onClick={() => onImportPreviewAsFewShot(item)}
          >
            <FaUpload size={14} />
            Import as Few Shot
          </button>
        </div>
      </div>

      {/* Body */}
      {isExpanded && (
        <div className={styles.comparisonBody}>
          {/* Show the included input columns */}
          {columns_used && columns_used.length > 0 && (
            <div className={styles.includedInputDataContainer}>
              <div className={styles.includedInputDataHeader}>Included input columns:</div>
              <div className={styles.columnsUsedContainer}>
                {columns_used.map((c) => (
                  <span key={c} className={styles.columnTag}>
                    {c}
                  </span>
                ))}
              </div>
            </div>
          )}

          <div className={styles.sideBySideContainer}>
            {/* Left Column: Input Data */}
            <div className={styles.dataColumn}>
              <div className={styles.columnHeader}>Prompt Input Data</div>
              <div className={styles.columnContent}>
                {inputKeys.length > 0 ? (
                  inputKeys.map((key) => {
                    const val = prompt_input_data[key] ?? '';
                    return (
                      <div className={styles.dataRow} key={`input-${key}`}>
                        <div className={styles.dataLabel}>{key}</div>
                        {isRichText ? (
                         <ReactQuill
                           theme="snow"
                           value={safeQuillValue(val)}
                           readOnly
                           modules={{ toolbar: false }}
                         />
                        ) : (
                          <textarea
                            className={styles.dataValuePlainText}
                            value={String(val)}
                            readOnly
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>No input data found.</p>
                )}
              </div>
            </div>

            {/* Right Column: Generated Data */}
            <div className={styles.dataColumn}>
              <div className={styles.columnHeader}>Generated Data</div>
              <div className={styles.columnContent}>
                {finalGeneratedKeys.length > 0 ? (
                  finalGeneratedKeys.map((key) => {
                    const val = generated_data[key] ?? '';
                    return (
                      <div className={styles.dataRow} key={`generated-${key}`}>
                        <div className={styles.dataLabel}>{key}</div>
                        {isRichText ? (
                          <ReactQuill
                            className={styles.richTextEditorQuillRight}
                            theme="snow"
                            value={safeQuillValue(val)}
                            readOnly
                            modules={{ toolbar: false }}
                          />
                        ) : (
                          <textarea
                            className={styles.dataValuePlainText}
                            value={String(val)}
                            readOnly
                          />
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p>No generated data available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

/**
 * Displays a simple item preview (image/title/brand/ID).
 */
function ItemPreview({ item }) {
  const { id, image_url, title, brand } = item;

  return (
    <div className={styles.itemPreviewContainer}>
      <div className={styles.itemPreviewImageContainer}>
        {image_url ? (
          <img src={image_url} alt={title || 'Preview'} className={styles.itemPreviewImage} />
        ) : (
          <div className={styles.itemPreviewImage} style={{ backgroundColor: '#ccc' }} />
        )}
      </div>
      <div className={styles.itemPreviewDetails}>
        <p className={styles.itemPreviewTitle}>{title || 'No Title'}</p>
        <p className={styles.itemPreviewBrand}>
          {brand ? `Brand: ${brand}` : 'No Brand'}
        </p>
        <p className={styles.itemPreviewId}>
          {id ? `ID: ${id}` : 'No ID'}
        </p>
      </div>
    </div>
  );
}

export default PromptBuilderPdp;
