// PopupRowDataPdp.jsx 
import React, { useState, useEffect, memo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FaTimes, FaDatabase, FaMagic, FaToggleOn, FaToggleOff } from 'react-icons/fa';
import styles from './css/PopupRowDataPdp.module.css'; 

const PDP_COLUMNS = [
  'meta_title', 'meta_description', 'h1', 'h2', 'intro',
  'description', 'usp', 'faq',
  'custom_field_1', 'custom_field_2', 'custom_field_3',
  'custom_field_4', 'custom_field_5', 'custom_field_6',
  'custom_field_7', 'custom_field_8', 'custom_field_9',
  'custom_field_10'
];

// Utility function to convert HTML to plain text (No longer needed for displaying raw HTML)
const htmlToPlainText = (html) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

// Function to get display name
const getDisplayName = (col) => {
  if (col.startsWith('source_')) {
    return col.replace('source_', '');
  }
  // Optionally, format other column names if needed
  return col.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
};

// Function to check if a URL is an image
const isImageURL = (url) => {
  if (typeof url !== 'string') return false;
  return(/\.(jpeg|jpg|gif|png|webp|bmp|svg)$/i.test(url));
};

const PopupRowDataPdp = ({
  isOpen,
  onClose,
  rowData,
  popupSaving,         // Indicates if the save operation is in progress
  saveSuccess,        // Indicates if the save operation was successful
  onSave,              // Function to handle save action
}) => {
  const [editablePdpColumns, setEditablePdpColumns] = useState({});
  const [isRichText, setIsRichText] = useState(true); // Global editing mode
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    if (rowData) {
      const initialEditable = {};
      PDP_COLUMNS.forEach(col => {
        initialEditable[col] = rowData[col] || '';
      });
      setEditablePdpColumns(initialEditable);
      setIsRichText(true); // Reset to default mode on new data
      setHasUnsavedChanges(false);
    }
  }, [rowData]);

  if (!isOpen || !rowData) return null;

  // Extract PDP Columns in the specified order
  const pdpColumns = PDP_COLUMNS
    .filter(col => rowData.hasOwnProperty(col))
    .map(col => ({ key: col, value: editablePdpColumns[col] }));

  // Extract Regular Columns: only columns starting with 'source_'
  const regularColumns = Object.entries(rowData)
    .filter(([key]) => key.startsWith('source_'))
    .map(([key, value]) => ({ key, value }));

  // Prevent clicks inside the popup from closing it
  const handleInnerClick = (e) => {
    e.stopPropagation();
  };

  // Helper function to get the value from source_ column if exists
  const getSourceValue = (sourceKey, fallbackKey) => {
    return rowData[sourceKey] || rowData[fallbackKey] || 'N/A';
  };

  // Helper function to get the image link from source_image_link if exists
  const getSourceImageLink = () => {
    return rowData['source_image_link'] || rowData['image_link'] || null;
  };

  // Handle changes in the editors
  const handlePdpChange = (key, value) => {
    setEditablePdpColumns(prev => ({
      ...prev,
      [key]: value,
    }));
    setHasUnsavedChanges(true);
  };

  // Handle save action
  const handleSave = () => {
    if (onSave) {
      // Prepare only the fields that have changed
      const updatedFields = {};
      PDP_COLUMNS.forEach(col => {
        if (editablePdpColumns[col] !== (rowData[col] || '')) {
          updatedFields[col] = editablePdpColumns[col];
        }
      });

      if (Object.keys(updatedFields).length > 0) {
        onSave(updatedFields);
        setHasUnsavedChanges(false);
      } else {
        // No changes to save
        alert('No changes detected to save.');
      }
    }
  };

  // New: Handle attempts to close the popup
  const handleAttemptClose = () => {
    if (hasUnsavedChanges) {
      const confirmClose = window.confirm(
        "You have unsaved changes. Are you sure you want to close without saving?"
      );
      if (confirmClose) {
        onClose();
      }
      // If the user cancels, do nothing and keep the popup open
    } else {
      onClose();
    }
  };

  return (
    <div className={`${styles.popupOverlay}`} onClick={handleAttemptClose}>
      <div className={styles.popupContentDual} onClick={handleInnerClick}>
        
        {/* Close Button */}
        <button
          className={styles.closeButton}
          onClick={handleAttemptClose}
          title="Close"
        >
          <FaTimes />
        </button>

        {/* Header Section */}
        <div className={styles.popupHeader}>
          <div className={styles.popupPreview}>
            <div className={styles.popupPreviewImage}>
              {getSourceImageLink() && isImageURL(getSourceImageLink()) ? (
                <img src={getSourceImageLink()} alt={getSourceValue('source_title', 'title')} />
              ) : (
                <div className={styles.noImage}>No Image Available</div>
              )}
            </div>
            <div className={styles.popupPreviewDetails}>
              <p><strong>ID:</strong> {rowData.id || 'N/A'}</p>
              <p><strong>Title:</strong> {getSourceValue('source_title', 'title')}</p>
              <p><strong>Brand:</strong> {getSourceValue('source_brand', 'brand')}</p>
            </div>
          </div>
          
          {/* Global Toggle Button */}
          <div className={styles.globalToggle}>
            <label htmlFor="toggle-editor" className={styles.toggleLabel}>
              {isRichText ? 'Rich Text Mode' : 'Rich Text Mode'}
            </label>
            <button
              id="toggle-editor"
              className={styles.toggleButton}
              onClick={() => setIsRichText(prev => !prev)}
              title="Toggle Editing Mode"
              aria-pressed={isRichText}
              role="switch"
              aria-label="Toggle editing mode between rich text and plain text"
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  setIsRichText(prev => !prev);
                }
              }}
            >
              {isRichText ? <FaToggleOn /> : <FaToggleOff />}
            </button>
          </div>
        </div>

        {/* Dual Content Section */}
        <div className={styles.dualContainer}>
          
          {/* LEFT SIDE: Regular Columns (only 'source_' columns) */}
          <div className={styles.leftSide}>
            <h3>Source Columns</h3>
            <div className={styles.leftContent}>
              {regularColumns.length > 0 ? (
                regularColumns.map(({ key, value }) => (
                  <div className={styles.rowItem} key={key}>
                    <div className={styles.rowItemKey}>
                      {getDisplayName(key)}{' '}
                      {key.startsWith('source_') ? (
                        <FaDatabase className={styles.columnIcon} title="Source column" />
                      ) : (
                        <FaMagic className={styles.columnIcon} title="Magic column" />
                      )}
                    </div>
                    <div className={styles.rowItemValue}>
                      {isImageURL(value) ? (
                        <img src={value} alt={getDisplayName(key)} className={styles.rowImage} />
                      ) : (
                        value || 'N/A'
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No Source Columns Available.</p>
              )}
            </div>
          </div>

          {/* RIGHT SIDE: PDP Columns with Toggleable Editors */}
          <div className={styles.rightSide}>
            <h3>PDP Columns</h3>
            <div className={styles.rightContent}>
              {pdpColumns.length > 0 ? (
                pdpColumns.map(({ key }) => (
                  <div className={styles.rowItem} key={key}>
                    <div className={styles.rowItemKey}>
                      {getDisplayName(key)}{' '}
                      <FaMagic className={styles.columnIcon} title="PDP column" />
                    </div>
                    <div className={styles.rowItemValue}>
                      {isRichText ? (
                        <ReactQuill
                          theme="snow"
                          value={editablePdpColumns[key]}
                          onChange={(content) => handlePdpChange(key, content)}
                          modules={{
                            toolbar: [
                              [{ 'header': [1, 2, 3, false] }],
                              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                              ['link', 'image'],
                              ['clean']
                            ],
                          }}
                          formats={[
                            'header',
                            'bold', 'italic', 'underline', 'strike', 'blockquote',
                            'list', 'bullet', 'indent',
                            'link', 'image'
                          ]}
                          className={styles.richTextEditor}
                        />
                      ) : (
                        <textarea
                          value={editablePdpColumns[key]}
                          onChange={(e) => handlePdpChange(key, e.target.value)}
                          className={styles.plainTextEditor}
                          rows={4}
                        />
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <p>No PDP Columns Available.</p>
              )}
            </div>
          </div>
        </div>

        {/* Optional "unsaved changes" or "save success" messages */}
        {hasUnsavedChanges && (
          <div className={styles.popupUnsaved}>
            You have unsaved changes.
          </div>
        )}
        {saveSuccess && (
          <div className={styles.popupSuccess}>
            Changes saved successfully!
          </div>
        )}

        {/* Sticky Save Button Container */}
        <div className={styles.stickySaveContainer}>
          <button
            className={styles.saveButton}
            onClick={handleSave}
            disabled={popupSaving || !hasUnsavedChanges}
            title="Save Changes"
          >
            {popupSaving ? 'Saving...' : 'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupRowDataPdp;
