// src/popups/PopupImportDefaultPromptPdp.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes, FaPlus, FaEdit, FaTrash, FaCheck, FaArrowLeft } from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';
import styles from './css/PopupImportDefaultPromptPdp.module.css';

/**
 * Props:
 *  - isOpen: boolean
 *  - onClose: function
 *  - promptType: 'system' | 'image' | 'instruction'
 *  - token: string (auth token)
 *  - isAdmin: boolean
 *  - onImport: function (callback that receives the prompt text from user selection)
 *
 * The popup:
 *   - loads all default prompts for the given promptType
 *   - shows them in a list
 *   - if user is NOT admin => can only select one to import
 *   - if user IS admin => can also add, edit, or delete them
 */
const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;

const PopupImportDefaultPromptPdp = ({
  isOpen,
  onClose,
  promptType,
  token,
  isAdmin,
  onImport,
}) => {
  const [defaultPrompts, setDefaultPrompts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedPrompt, setSelectedPrompt] = useState(null); // For highlight or for admin actions

  // Admin editing/creation
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [editorMode, setEditorMode] = useState(null); // 'create' or 'edit'
  const [tempPromptName, setTempPromptName] = useState('');
  const [tempPromptText, setTempPromptText] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  // ---- Fetch all default prompts for this promptType ----
  useEffect(() => {
    if (isOpen && promptType) {
      fetchDefaultPrompts();
    }
  }, [isOpen, promptType]);

  const fetchDefaultPrompts = async () => {
    try {
      setLoading(true);
      setErrorMsg('');

      const response = await axios.get(`${API_URL_PDP}/get-default-prompts-pdp`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          prompt_type: promptType,
        },
      });

      if (response.status === 200) {
        setDefaultPrompts(response.data.prompts || []);
      } else {
        setErrorMsg('Failed to load default prompts.');
      }
    } catch (error) {
      console.error('Error fetching default prompts:', error);
      setErrorMsg(error?.response?.data?.message || 'Error loading default prompts.');
    } finally {
      setLoading(false);
    }
  };

  // ---- Handle user selection (for import) ----
  const handleSelectPrompt = (prompt) => {
    setSelectedPrompt(prompt);
  };

  const handleImportPrompt = (prompt) => {
    // Callback to parent with the prompt text
    if (onImport) {
      onImport(prompt.text);
    }
    onClose();
  };

  // ---- Admin: open create form ----
  const handleOpenCreateForm = () => {
    setEditorMode('create');
    setTempPromptName('');
    setTempPromptText('');
    setShowAdminPanel(true);
  };

  // ---- Admin: open edit form ----
  const handleOpenEditForm = (prompt) => {
    setEditorMode('edit');
    setTempPromptName(prompt.name); // keep the original name
    setTempPromptText(prompt.text);
    setSelectedPrompt(prompt);
    setShowAdminPanel(true);
  };

  // ---- Cancel admin form ----
  const handleCancelAdminForm = () => {
    setShowAdminPanel(false);
    setEditorMode(null);
    setSelectedPrompt(null);
    setTempPromptName('');
    setTempPromptText('');
  };

  // ---- Admin: create or update prompt in DB ----
  const handleSavePrompt = async () => {
    try {
      setIsSaving(true);
      setErrorMsg('');

      if (!tempPromptName.trim() || !tempPromptText.trim()) {
        setErrorMsg('Please provide both a prompt name and prompt text.');
        return;
      }

      if (editorMode === 'create') {
        // Create new default prompt
        const response = await axios.post(
          `${API_URL_PDP}/create-default-prompt-pdp`,
          {
            prompt_type: promptType,
            prompt_name: tempPromptName.trim(),
            prompt_text: tempPromptText,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 200) {
          // Refresh list
          await fetchDefaultPrompts();
          handleCancelAdminForm();
        } else {
          setErrorMsg('Failed to create new default prompt.');
        }
      } else if (editorMode === 'edit') {
        // Update existing default prompt
        // Might optionally allow changing the name or keep name the same
        const oldName = selectedPrompt ? selectedPrompt.name : '';
        const response = await axios.put(
          `${API_URL_PDP}/update-default-prompt-pdp`,
          {
            prompt_type: promptType,
            old_prompt_name: oldName,
            new_prompt_name: tempPromptName.trim(),
            prompt_text: tempPromptText,
          },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (response.status === 200) {
          // Refresh list
          await fetchDefaultPrompts();
          handleCancelAdminForm();
        } else {
          setErrorMsg('Failed to update default prompt.');
        }
      }
    } catch (error) {
      console.error('Error saving prompt:', error);
      setErrorMsg(error?.response?.data?.message || 'Error saving prompt.');
    } finally {
      setIsSaving(false);
    }
  };

  // ---- Admin: delete a default prompt ----
  const handleDeletePrompt = async (prompt) => {
    const confirmDeletion = window.confirm(
      `Are you sure you want to delete the default prompt "${prompt.name}"? This cannot be undone.`
    );
    if (!confirmDeletion) return;

    try {
      setLoading(true);

      const response = await axios.delete(`${API_URL_PDP}/delete-default-prompt-pdp`, {
        headers: { Authorization: `Bearer ${token}` },
        data: {
          prompt_type: promptType,
          prompt_name: prompt.name,
        },
      });

      if (response.status === 200) {
        // Remove from local state or re-fetch
        setDefaultPrompts((prev) => prev.filter((p) => p.name !== prompt.name));
      } else {
        setErrorMsg('Failed to delete the prompt.');
      }
    } catch (error) {
      console.error('Error deleting default prompt:', error);
      setErrorMsg(error?.response?.data?.message || 'Error deleting default prompt.');
    } finally {
      setLoading(false);
    }
  };

  // ---- Overlay click handler ----
  const handleOverlayClick = () => {
    onClose();
  };

  // ---- Content click (stop propagation) ----
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <div className={styles.popupOverlay} onClick={handleOverlayClick}>
      <div className={styles.popupContent} onClick={handleContentClick}>
        <div className={styles.popupHeader}>
          {/* We can show the promptType in the title for clarity */}
          <h2>Import {promptType.charAt(0).toUpperCase() + promptType.slice(1)} Prompt</h2>
          <button className={styles.closeButton} onClick={onClose} title="Close">
            <FaTimes />
          </button>
        </div>

        {loading && (
          <div className={styles.loadingContainer}>
            <ClipLoader size={24} color="#4758EB" />
            <p>Loading...</p>
          </div>
        )}

        {!loading && !showAdminPanel && (
          <div className={styles.popupBody}>
            {/* Error message display */}
            {errorMsg && <div className={styles.errorMessage}>{errorMsg}</div>}

            {defaultPrompts.length === 0 && (
              <div className={styles.noPrompts}>
                No default prompts found for "{promptType}".
              </div>
            )}

            {/* List of prompts */}
            {defaultPrompts.map((p) => (
              <div
                key={p.name}
                className={`${styles.promptItem} ${
                  selectedPrompt && selectedPrompt.name === p.name ? styles.selected : ''
                }`}
                onClick={() => handleSelectPrompt(p)}
                onDoubleClick={() => handleImportPrompt(p)} // Added double-click handler
              >
                <div className={styles.promptName}>{p.name}</div>
                <div className={styles.promptActions}>
                  {/* Admin actions */}
                  {isAdmin && (
                    <>
                      <button
                        className={`${styles.actionButton} ${styles.editButton}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenEditForm(p);
                        }}
                        title="Edit this default prompt"
                      >
                        <FaEdit size={14} />
                      </button>
                      <button
                        className={`${styles.actionButton} ${styles.deleteButton}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeletePrompt(p);
                        }}
                        title="Delete this default prompt"
                      >
                        <FaTrash size={14} />
                      </button>
                    </>
                  )}
                  {/* Import button */}
                  <button
                    className={`${styles.actionButton} ${styles.importButton}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleImportPrompt(p);
                    }}
                    title="Import this prompt"
                  >
                    <FaCheck size={14} />
                  </button>
                </div>
              </div>
            ))}

            {/* Admin "Create new" button */}
            {isAdmin && (
              <button className={styles.newPromptButton} onClick={handleOpenCreateForm}>
                <FaPlus /> Create New Default Prompt
              </button>
            )}
          </div>
        )}

        {/* Admin create/edit panel */}
        {showAdminPanel && (
          <div className={styles.adminPanel}>
            <button className={styles.backButton} onClick={handleCancelAdminForm} title="Back">
              <FaArrowLeft /> Back
            </button>
            <h3>
              {editorMode === 'create' ? 'Create Default Prompt' : 'Edit Default Prompt'}
            </h3>
            {errorMsg && <div className={styles.errorMessage}>{errorMsg}</div>}

            <label className={styles.fieldLabel}>Prompt Name</label>
            <input
              type="text"
              className={styles.textInput}
              value={tempPromptName}
              onChange={(e) => setTempPromptName(e.target.value)}
              disabled={isSaving}
              placeholder="Enter prompt name"
            />

            <label className={styles.fieldLabel}>Prompt Text</label>
            <textarea
              className={styles.textArea}
              value={tempPromptText}
              onChange={(e) => setTempPromptText(e.target.value)}
              disabled={isSaving}
              placeholder="Enter prompt text"
            />

            <button
              className={styles.saveButton}
              onClick={handleSavePrompt}
              disabled={isSaving}
            >
              {isSaving
                ? 'Saving...'
                : editorMode === 'create'
                ? 'Create Prompt'
                : 'Save Changes'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupImportDefaultPromptPdp;
