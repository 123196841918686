import React, { useEffect, useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import ClipLoader from 'react-spinners/ClipLoader';
import axios from 'axios';
import styles from './css/PopupPdpBulkActions.module.css';

const PopupPdpBulkActions = ({
  isOpen,
  onClose,
  token,
  clientName,
  selectedCount,
  filteredCount,
  allCount,
  filters = [],
  pdpColumns = [],
  promptCombinationsEndpoint = '/get-prompt-combinations',
}) => {
  const [activeTab, setActiveTab] = useState('generate');
  const [promptCombinations, setPromptCombinations] = useState([]);
  const [isLoadingPrompts, setIsLoadingPrompts] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [scope, setScope] = useState('selected');
  const [selectedPromptConfig, setSelectedPromptConfig] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const popupRef = useRef(null); // Ref for the popup container

  useEffect(() => {
    if (!isOpen || !clientName) return;
    (async () => {
      setIsLoadingPrompts(true);
      try {
        const res = await axios.get(
          `${promptCombinationsEndpoint}?client_name=${encodeURIComponent(clientName)}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (res.status === 200 && res.data.prompt_combinations) {
          setPromptCombinations(res.data.prompt_combinations);
        } else {
          setPromptCombinations([]);
        }
      } catch (error) {
        console.error('Error fetching prompt combos:', error);
        setPromptCombinations([]);
      } finally {
        setIsLoadingPrompts(false);
      }
    })();
  }, [isOpen, clientName, token, promptCombinationsEndpoint]);

  // Close popup when clicking outside the popup container
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const toggleField = (field) => {
    setSelectedFields((prev) => {
      if (prev.includes(field)) {
        return prev.filter((f) => f !== field);
      }
      return [...prev, field];
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const payload = {
        client_name: clientName,
        action: activeTab === 'generate' ? 'generate_pdp_columns' : 'change_status',
        columns: selectedFields,
        scope,
        filters,
        prompt_config: selectedPromptConfig,
      };

      console.log('Submitting bulk action:', payload);

      onClose();
    } catch (error) {
      console.error('Bulk action failed:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.popupOverlay}>
      <div className={styles.popupInner} ref={popupRef}>
        <button className={styles.cancelJobXButton} onClick={onClose} title="Close">
          <FaTimes />
        </button>
        <div className={styles.title}>Bulk Actions</div>
        <div className={styles.segmentedControl}>
          <button
            className={activeTab === 'generate' ? `${styles.segmentButton} ${styles.activeSegment}` : styles.segmentButton}
            onClick={() => setActiveTab('generate')}
          >
            Generate PDP Columns
          </button>
          <button
            className={activeTab === 'status' ? `${styles.segmentButton} ${styles.activeSegment}` : styles.segmentButton}
            onClick={() => setActiveTab('status')}
          >
            Change Status
          </button>
        </div>
        <div className={styles.description}>
          {activeTab === 'generate'
            ? 'Select which PDP columns you want to generate/overwrite...'
            : 'Select which PDP columns whose status you want to change...'}
        </div>
        <div className={styles.fieldSelectionSection}>
          <div className={styles.fieldsHeader}>
            <div className={styles.subTitle}>PDP Columns</div>
            <div className={styles.fieldsControls}>
              <button
                className={styles.selectAllButton}
                onClick={() => {
                  if (selectedFields.length < pdpColumns.length) {
                    setSelectedFields(pdpColumns);
                  } else {
                    setSelectedFields([]);
                  }
                }}
              >
                {selectedFields.length < pdpColumns.length ? 'Select All' : 'Unselect All'}
              </button>
              <div className={styles.selectedCount}>{selectedFields.length} selected</div>
            </div>
          </div>
          <div className={styles.fieldsGrid}>
            {pdpColumns.length === 0 && <div className={styles.noResults}>No PDP columns available</div>}
            {pdpColumns.map((col) => (
              <label key={col} className={styles.fieldItem}>
                <input
                  type="checkbox"
                  checked={selectedFields.includes(col)}
                  onChange={() => toggleField(col)}
                />
                <div className={styles.fieldName}>{col}</div>
              </label>
            ))}
          </div>
        </div>
        <div className={styles.scopeSection}>
          <div className={styles.subTitle}>Select Scope</div>
          <div className={styles.scopeSelector}>
            <button
              className={scope === 'selected' ? `${styles.scopeButton} ${styles.activeScope}` : styles.scopeButton}
              onClick={() => setScope('selected')}
            >
              Selected IDs ({selectedCount})
            </button>
            <button
              className={scope === 'filtered' ? `${styles.scopeButton} ${styles.activeScope}` : styles.scopeButton}
              onClick={() => setScope('filtered')}
            >
              Filtered IDs ({filteredCount})
            </button>
            <button
              className={scope === 'all' ? `${styles.scopeButton} ${styles.activeScope}` : styles.scopeButton}
              onClick={() => setScope('all')}
            >
              All IDs ({allCount})
            </button>
          </div>
        </div>
        {activeTab === 'generate' && (
            <div className={styles.promptSection}>
                <div className={styles.subTitle}>Select Prompt Configuration</div>
                {isLoadingPrompts ? (
                <ClipLoader size={20} color="#4758EB" />
                ) : (
                <select
                    className={styles.dropdown}
                    value={selectedPromptConfig}
                    onChange={(e) => setSelectedPromptConfig(e.target.value)}
                >
                    <option value="">-- Select a configuration --</option>
                    {promptCombinations.map((combo) => (
                    <option key={combo} value={combo}>
                        {combo}
                    </option>
                    ))}
                </select>
                )}
            </div>
            )}
        <div className={styles.actionButtons}>
          <button className={styles.primaryActionButton} onClick={handleSubmit} disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <span className={styles.spinner}></span>
                <span>Working...</span>
              </>
            ) : activeTab === 'generate' ? (
              'Generate'
            ) : (
              'Change Status'
            )}
          </button>
          <button className={styles.secondaryActionButton} onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupPdpBulkActions;
