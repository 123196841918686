import React, { useState, useEffect } from 'react';
import axios from 'axios'; // or your centralized axiosInstance
import { FaTimes, FaCheck } from 'react-icons/fa';
import styles from './css/PopupCreatePromptCombinationPdp.module.css';

const API_URL_PDP = process.env.REACT_APP_API_URL_PDP;

const PopupCreatePromptCombinationPdp = ({
  isOpen,
  onClose,
  selectedClient,
  token,
  onCreated // callback to parent after creation
}) => {
  const [promptName, setPromptName] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const [isAvailable, setIsAvailable] = useState(null); // null = not checked, true = available, false = taken
  const [errorMsg, setErrorMsg] = useState('');
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    // Debounce mechanism: Check availability 0.8 seconds after user stops typing
    const timer = setTimeout(() => {
      if (promptName.trim().length > 0) {
        handleCheckAvailability();
      } else {
        setIsAvailable(null);
      }
    }, 800);

    // Cleanup the timer if promptName changes before 0.8 seconds
    return () => clearTimeout(timer);
  }, [promptName, selectedClient, token]); // Re-run if promptName, selectedClient, or token changes

  if (!isOpen) {
    return null; // Don't render if popup is closed
  }

  // Check name availability
  const handleCheckAvailability = async () => {
    setIsChecking(true);
    setIsAvailable(null);
    setErrorMsg('');

    try {
      // Example endpoint: /check-prompt-combination
      const response = await axios.post(
        `${API_URL_PDP}/check-prompt-combination`,
        {
          client_name: selectedClient,
          prompt_combination_name: promptName
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        const { isTaken } = response.data; 
        setIsAvailable(!isTaken); // If it's taken => false, else => true
      } else {
        setErrorMsg('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Check availability error:', error);
      setErrorMsg(error?.response?.data?.message || 'Failed to check availability.');
    } finally {
      setIsChecking(false);
    }
  };

  // Create the prompt configuration
  const handleCreatePromptCombination = async () => {
    setCreating(true);
    setErrorMsg('');

    try {
      // Example endpoint: /create-prompt-combination
      const response = await axios.post(
        `${API_URL_PDP}/create-prompt-combination`,
        {
          client_name: selectedClient,
          prompt_combination_name: promptName
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        // Optionally, you can show a success message or call a parent callback
        if (onCreated) {
          onCreated(promptName);
        }
        onClose();
      } else {
        setErrorMsg('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Create prompt combination error:', error);
      setErrorMsg(error?.response?.data?.message || 'Failed to create the prompt combination.');
    } finally {
      setCreating(false);
    }
  };

  const handleCancel = () => {
    onClose();
    // Reset fields if desired:
    setPromptName('');
    setIsAvailable(null);
    setErrorMsg('');
  };

  const handlePromptNameChange = (e) => {
    setPromptName(e.target.value);
    setIsAvailable(null); // reset availability if user changes input again
  };

  const isCreateEnabled = isAvailable && promptName.trim().length > 0 && !creating;

  // Handler for overlay click
  const handleOverlayClick = () => {
    handleCancel();
  };

  // Prevent click inside the popup from closing it
  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.popupOverlay} onClick={handleOverlayClick}>
      <div className={styles.popupContent} onClick={handleContentClick}>
        <div className={styles.popupHeader}>
          <h2>Create New Prompt Configuration</h2>
          <button className={styles.closeButton} onClick={handleCancel}>
            <FaTimes size={16} />
          </button>
        </div>

        <div className={styles.popupBody}>
          <label className={styles.fieldLabel}>Prompt Configuration Name:</label>
          <input
            type="text"
            className={styles.textInput}
            value={promptName}
            onChange={handlePromptNameChange}
            placeholder="Enter a name (e.g. 's360 shirt prompt')"
          />

          {/* Availability status */}
          {errorMsg && <div className={styles.errorMessage}>{errorMsg}</div>}

          {isChecking && <div className={styles.infoMessage}>Checking availability...</div>}

          {isAvailable === true && !isChecking && (
            <div className={styles.successMessage}>This name is available!</div>
          )}
          {isAvailable === false && !isChecking && (
            <div className={styles.warningMessage}>This name is taken. Please try another.</div>
          )}
        </div>

        <div className={styles.popupFooter}>
          {/* Create Button */}
          <button
            className={styles.createButton}
            onClick={handleCreatePromptCombination}
            disabled={!isCreateEnabled}
          >
            {creating ? 'Creating...' : 'Create Prompt Configuration'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupCreatePromptCombinationPdp;
